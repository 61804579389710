enum ActionTypes {
  'SET_SETTINGS',
  'SET_IS_CHANGED'
}

export type ModuleReducerAction = {
  type: ActionTypes,
  payload: any
}

export type ModuleReducerState<T = any> = {
  settings: T,
  isChanged: boolean
}

export const moduleInitState: ModuleReducerState = {
  settings: null,
  isChanged: false
}

export const setSettings = (value: any) => ({
  type: ActionTypes.SET_SETTINGS,
  payload: value
})

export const setIsChanged = (value: boolean) => ({
  type: ActionTypes.SET_IS_CHANGED,
  payload: value
})

export const moduleReducer = (state: ModuleReducerState, action: ModuleReducerAction): ModuleReducerState => {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case ActionTypes.SET_IS_CHANGED:
      return {
        ...state,
        isChanged: action.payload
      }
    default:
      return state
  }
}
