export const typesSelect: { [key: string]: string | number } = {
  'BOOL': 0,
  'BYTE': 1,
  'UINT16': 2,
  'UINT32': 3,
  'UINT64': 4,
  'INT64': 5,
  'FLOAT': 6,
  'DOUBLE': 7,
  'STRING': 8,
  'DATETIME': 9
}
export const qualitySelect = {
  ' -1 (UNDEFINED)': -1,
  ' 0 (OK)': 0,
  ' 1 (MANUAL_ENTRY)': 1,
  ' 32 (OUTDATE)': 32,
  ' 64 (UNCERTAIN)': 64,
  ' 128 (ERROR)': 128,
  ' 129 (INCORRECT_DATA)': 129,
  ' 130 (EXT_TIMEOUT)': 130,
  ' 131 (EXT_FAILURE)': 131,
}
