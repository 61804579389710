import type { Directory, DirTreeData } from 'entities/directory'

export const createDataTreeArr = (data: Directory[]): DirTreeData[] => {
  const root = data.filter(item => item.dir === '0')

  return root.map(r => makeDataStructure(r, data))
}

const addChildren = (arr: Directory[], children: string[]): DirTreeData[] => (
  children.map(child => {
    const curr = arr.find(item => item.uid === child)!

    return makeDataStructure(curr, arr)
  })
)

const makeDataStructure = (obj: Directory, arr:  Directory[]) => (
  {
    title: obj.name,
    key: `dir-${obj.uid}`,
    isLeaf: !obj.subdirectories.length,
    subdirs: obj.subdirectories,
    objects: obj.objects,
    dir: obj.dir,
    children: obj.subdirectories.length
      ? addChildren(arr, obj.subdirectories)
      : []
  }
)
