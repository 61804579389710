import { ChannelsResponse } from 'entities/channel'
import { DirTreeData } from 'entities/directory'
import type { ModuleSettings } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'
import { createObjectsArray } from 'shared/lib/create-objects-array'
import type { VisorlabsObject, VisorlabsTableData } from '../types'

export const createTableData = (settings: ModuleSettings<VisorlabsObject>, channelsData: ChannelsResponse | undefined, selectedFolder: DirTreeData): VisorlabsTableData[] => {
  const objects = createObjectsArray(settings, +keyToId(selectedFolder.key))

  return settings.config.objects_list.reduce<VisorlabsTableData[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      if (channelsData) {
        const channelsObj = {
          primary: { name: '', id: '' },
          person: { name: '', id: '' },
          zone: { name: '', id: '' },
        }

        channelsData.channels.forEach(ch => {
          switch (true) {
            case ch.uid === curr.object.primary_category_channel:
              channelsObj.primary = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.person_id_channel:
              channelsObj.person = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.zone_channel:
              channelsObj.zone = { name: ch.name, id: ch.uid }
              break
          }
        })

        acc.push({
          key: 'object-' + curr.ind,
          label: curr.object.label,
          primary_category: channelsObj.primary,
          person_id: channelsObj.person,
          zone: channelsObj.zone,
        })
      } else {
        acc.push({
          key: 'object-' + curr.ind,
          label: curr.object.label,
          primary_category: { name: '', id: '' },
          person_id: { name: '', id: '' },
          zone: { name: '', id: '' },
        })
      }
    }

    return acc
  }, [])
}
