import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import { ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import { ServerForm } from 'shared/ui/form/server-form'
import type { ServerSettingsType } from 'shared/ui/form/server-form'

export const WebSocket: FC = () => {
  const {
    state: { settings, isChanged },
    reducerDispatch
  } = useContext<ModuleContextType<ServerSettingsType>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalActive, setIsModalActive] = useState<boolean>(false)
  const [channelsArr, setChannelsArr] = useState<string[]>([])
  const [boundChannel, setBoundChannel] = useState<{ key: string, name: string } | null>(null)
  const [isBindLoading, setIsBindLoading] = useState<boolean>(false)
  const { data } = useChannels(channelsArr)
  const { mutate } = useSWRConfig()

  useEffect(() => {
    if (settings && settings.channel)
      setChannelsArr([settings.channel])
  }, [settings])

  useEffect(() => {
    if (data && data.channels.length)
      setBoundChannel({ key: 'channel-' + data.channels[0].uid, name: data.channels[0].name })
  }, [data])

  const onSaveBindingHandler = async (data: Partial<ChannelsTableData>[]) => {
    if (settings) {
      setIsBindLoading(true)
      const set = omitObject(settings, ['uid'])

      set.channel = keyToId(String(data[0].key))

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setIsBindLoading(false)
          setIsModalOpen(false)
          setTimeout(() => setIsModalActive(false), 300)
        })
    }
  }

  return (
    <>
      <div className={'content-wrapper'}>
        <Button
          type="primary"
          size={'small'}
          style={{ marginBottom: '10px' }}
          onClick={() => {
            setIsModalOpen(true)
            setIsModalActive(true)
          }}
        >
          Привязать канал
        </Button>

        <ServerForm
          settings={settings}
          isChanged={isChanged}
          onChange={(field, val) => {
            reducerDispatch(setSettings({
              ...settings,
              [field]: val
            }))
            reducerDispatch(setIsChanged(true))
          }}
          onValidate={isValid => reducerDispatch(setIsChanged(isValid))}
        />
      </div>

      {isModalActive && (
        <BindingChannels
          boundChannels={boundChannel ? [boundChannel] : []}
          isMultiple={false}
          isLoading={isBindLoading}
          isOpen={isModalOpen}
          onSave={onSaveBindingHandler}
          onClose={() => {
            setIsModalOpen(false)
            setTimeout(() => setIsModalActive(false), 300)
          }}
        />
      )}
    </>
  )
}
