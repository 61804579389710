import { Channel, ChannelsData, ChannelsTableData } from 'entities/channel'
import formatDate from 'shared/lib/format-date'

export const generateTableData = (prevData: ChannelsData | null, data: { query: string | null, items: Channel[] } | null): ChannelsTableData[] | undefined => {
  if (prevData && data) {
    // Проверяем что пришли обновленные данные, а не новые
    if ((prevData.query === data.query) && (prevData.items.length === data.items.length)) {
      return data.items.map((c, idx) => {
        const isChangedArr: Set<string> = prevData.items.length ? prevData.items[idx].changed : new Set()
        const commFields = {
          key: 'channel-' + c.uid,
          id: c.id,
          time: (c.time !== '0') ? formatDate(+c.time) : '',
        }

        /*
        * Проверяем изменялось ли это поле в режиме редактирования таблицы.
        * Если да, то отображаем измененные данные.
        * */
        if (isChangedArr.size) {
          return {
            ...commFields,
            ...{
              name: isChangedArr.has('name') ? prevData.items[idx].name : c.name,
              type: isChangedArr.has('type') ? prevData.items[idx].type : c.type,
              quality: isChangedArr.has('quality') ? prevData.items[idx].quality : c.quality,
              value: isChangedArr.has('value') ? prevData.items[idx].value : c.value,
              group: isChangedArr.has('group') ? prevData.items[idx].group : c.group,
              description: isChangedArr.has('description') ? prevData.items[idx].description : c.description,
              changed: isChangedArr
            }
          }
        } else {
          return {
            ...commFields,
            ...{
              name: c.name,
              type: c.type,
              quality: c.quality,
              value: c.value,
              group: c.group,
              description: c.description,
              changed: new Set()
            }
          }
        }
      })
    } else {
      return data.items.map(c => ({
        key: 'channel-' + c.uid,
        id: c.id,
        quality: c.quality,
        value: c.value,
        time: (c.time !== '0') ? formatDate(+c.time) : '',
        name: c.name,
        type: c.type,
        group: c.group,
        description: c.description,
        changed: new Set()
      }))
    }
  }
}
