import { MenuProps } from 'antd'

export const configItems: MenuProps['items'] = [
  {
    key: 'conf-1',
    label: 'Новая'
  },
  {
    key: 'conf-2',
    label: 'Открыть'
  },
  {
    key: 'conf-3',
    label: 'Сохранить'
  },
  {
    key: 'conf-4',
    label: 'Сохранить как...'
  },
  {
    key: 'conf-5',
    label: 'Импорт'
  },
  {
    key: 'conf-6',
    label: 'Экспорт'
  },
]
