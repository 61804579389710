import { typesSelect } from 'entities/channel'
import type { ChannelColumns } from 'entities/channel'

export const COLUMNS_ADDED: any = [
  {
    title: 'Имя канала',
    dataIndex: 'name',
    key: 'name',
  },
].map(col => ({
  ...col,
  onCell: (record: any) => ({
    record,
    dataIndex: col.dataIndex,
  }),
}))

export const COLUMNS: ChannelColumns = [
  {
    title: 'Номер',
    dataIndex: 'id',
    key: 'id',
    width: 80,
    sorter: true,
  },
  {
    title: 'Имя канала',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Тип',
    key: 'type',
    dataIndex: 'type',
    render: (text: string) => Object.keys(typesSelect)[+text]
  },
  {
    title: 'Группа',
    dataIndex: 'group',
    key: 'group',
    sorter: true,
  },
  {
    title: 'Описание',
    key: 'description',
    dataIndex: 'description',
    editable: true,
  },
]
