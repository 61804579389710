import React, { FC } from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useAppDispatch } from 'app/store/hooks'
import BlockHeader from 'shared/ui/block-header'
import { closeLogs } from 'entities/log'

const Header: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <BlockHeader
      closeButton={
        <Button
          type="default"
          icon={<CloseOutlined />}
          size={'small'}
          onClick={() => dispatch(closeLogs())}
        />
      }
    />
  )
}

export default Header
