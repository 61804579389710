export const fileUploader = (fileTypes: string = '') => {
  return new Promise<{ data: string | ArrayBuffer | null, name: string }>(res => {
    const fileInput = document.createElement('input')

    fileInput.type = 'file'
    fileInput.accept = fileTypes

    fileInput.addEventListener('change', () => {
      if (fileInput.files) {
        const file = fileInput.files[0]
        const reader = new FileReader()

        reader.readAsText(file)
        reader.onload = () => res({ data: reader.result, name: file.name })
      }
    })
    fileInput.click()
  })
}
