import React, { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'
import { App as AntApp } from 'antd'
import { setStructure, setChannelsLength } from 'entities/project'
import { setDirectories } from 'entities/directory'
import { setModules, setPlugins, setTypes } from 'entities/module'
import { API_URL } from 'shared/config'
import { getProjects } from 'shared/api/projects'
import { DIR_URL, getDirectories } from 'shared/api/directories'
import { getModules, getPlugins, getTypes } from 'shared/api/modules'
import { getCoreInfo } from 'shared/api/app'
import multiFetcher from 'shared/lib/api/multi-fetcher'
import { ContentProvider } from './providers/content-provider'
import { useAppDispatch } from './store'
import Main from '../pages/main'

const App = () => {
  const dispatch = useAppDispatch()
  const { data: projects } = useSWRImmutable(`${API_URL}/projects`, getProjects)
  const { data: directories } = useSWRImmutable(DIR_URL, () => getDirectories())
  const { data: plugins } = useSWRImmutable([getTypes, getPlugins], multiFetcher)
  const { data: modules } = useSWRImmutable(`${API_URL}/modules`, getModules)
  const { data: info } = useSWRImmutable(`${API_URL}/info`, getCoreInfo)

  useEffect(() => {
    if (projects) dispatch(setStructure(projects))
    if (directories) dispatch(setDirectories(directories))
    if (modules) dispatch(setModules(modules))
    if (info) dispatch(setChannelsLength(+info.channels_count))

    if (plugins) {
      dispatch(setTypes(plugins[0].plugins))
      dispatch(setPlugins(plugins[1].plugins))
    }
  }, [projects, directories, plugins, modules, info])

  return (
    <AntApp>
      <ContentProvider>
        {(props: any) => <Main {...props} />}
      </ContentProvider>
    </AntApp>
  )
}

export default App
