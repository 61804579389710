import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { dispatchEvent } from 'shared/lib/custom-events'
import { filterNums } from 'shared/lib/filter-nums'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'
import type { RealTracFields, RealTracSettingsType } from './types'

export const RealTrac: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<RealTracSettingsType>>(ModuleContext)
  const [boundChannel, setBoundChannel] = useState<ChannelItem[]>([])
  const [channelsArr, setChannelsArr] = useState<string[]>([])
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const { data: channelData } = useChannels(channelsArr)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        url: settings.url,
        login: settings.auth_login,
        password: settings.auth_passwd,
        polling_period: settings.polling_alarms_period,
      })

      if (settings.event_channel)
        setChannelsArr([settings.event_channel])
      else
        setChannelsArr([])
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      form.validateFields()
        .then(() => reducerDispatch(setIsChanged(true)))
        .catch(() => reducerDispatch(setIsChanged(false)))
  }, [values])

  useEffect(() => {
    if (channelData)
      if (channelData.channels[0].exist) {
        const channel = channelData.channels[0]

        setBoundChannel([{
          key: `channel-${channel.uid}`,
          name: channel.name
        }])

        form.setFieldValue('event_channel', channel.uid)
        form.setFieldValue('event_channel_name', channel.name)
      } else {
        setBoundChannel([])
        form.setFieldValue('event_channel', '0')
        form.setFieldValue('event_channel_name', 'Канал не найден')
      }
  }, [channelData])

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('event_channel', keyToId(String(data[0].key)))
      form.setFieldValue('event_channel_name', data[0].name)
      setBoundChannel([{ key: data[0].key || '', name: data[0].name || '' }])
      reducerDispatch(setSettings({
        ...settings,
        event_channel: form.getFieldValue('event_channel')
      }))
      reducerDispatch(setIsChanged(true))
    }
  }

  return (
    <div className={'content-wrapper'}>
      <Form
        name={'real-trac-form'}
        form={form}
        layout={'vertical'}
        className={'module'}
        onKeyUp={e => {
          if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
        }}
      >
        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={6}>
            <Form.Item<RealTracFields>
              label="Адрес сервера"
              name="url"
              rules={[{ required: true, message: 'Введите адрес сервера' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    url: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item<RealTracFields>
              label="Логин"
              name="login"
              rules={[{ required: true, message: 'Введите логин' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    auth_login: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item<RealTracFields>
              label="Пароль"
              name="password"
              rules={[{ required: true, message: 'Введите пароль' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    auth_passwd: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item<RealTracFields>
              label="Период опроса"
              name="polling_period"
            >
              <Input
                onKeyPress={filterNums}
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    polling_alarms_period: +e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
                onBlur={(e) => {
                  if (!e.target.value)
                    form.setFieldValue('polling_period', 1)
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={8}>
            <FormChannelBinding
              key={'event_channel_field'}
              label={'Привязанный канал ядра для события'}
              name={'event_channel'}
              compact
              onClick={() => {
                setIsBindingOpen(true)
                setIsBindingActive(true)
              }}
              onClear={() => {
                form.setFieldValue('event_channel', '')
                form.setFieldValue('event_channel_name', '')
                setBoundChannel([])
                reducerDispatch(setSettings({
                  ...settings,
                  event_channel: ''
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Col>
        </Row>
      </Form>

      {isBindingActive && (
        <BindingChannels
          boundChannels={boundChannel}
          isOpen={isBindingOpen}
          isMultiple={false}
          onSave={onSaveBindingHandler}
          onClose={() => {
            setIsBindingOpen(false)
            setTimeout(() => { setIsBindingActive(false) }, 300)
          }}
        />
      )}
    </div>
  )
}
