import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Form, Input, message, Modal, Select } from 'antd'
import { configItems } from './config'
import { useAppDispatch, useAppSelector } from 'app/store'
import { selectCurrentProject, selectList } from 'entities/project'
import { setExpandedDirs, setSelectedDirectory } from 'entities/directory'
import { setActiveModule, setSelectedPlugin } from 'entities/module'
import { resetTabs } from 'entities/tab'
import { setSelectedChannels } from 'entities/channel'
import { API_URL } from 'shared/config'
import type { MenuInfo } from 'shared/types'
import { exportProject, importProject, loadProject, newProject, saveProject } from 'shared/api/projects'
import { DIR_URL } from 'shared/api/directories'
import { getPlugins, getTypes } from 'shared/api/modules'
import { dispatchEvent } from 'shared/lib/custom-events'
import { getSHA256String } from 'shared/lib/get-SHA256-string'
import { fileSaver } from 'shared/lib/file-saver'
import { ASCIIToUTF8 } from 'shared/lib/ASCII-to-UTF-8'
import { fileUploader } from 'shared/lib/file-uploader'
import { AppDropdownMenu } from 'shared/ui/app-dropdown-menu'

const { Option } = Select

export const Configuration: FC = () => {
  const dispatch = useAppDispatch()
  const [selectedProject, setSelectedProject] = useState<string>('')
  const [newProjectName, setNewProjectName] = useState<string>('')
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isSaveAsModal, setIsSaveAsModal] = useState<boolean>(false)
  const [isNewModal, setIsNewModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const currentProject = useAppSelector(selectCurrentProject)
  const projects = useAppSelector(selectList)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  useEffect(() => {
    if (currentProject)
      setNewProjectName(currentProject)
  }, [currentProject])

  const resetProjectData = () => {
    mutate(DIR_URL)
    mutate([getTypes, getPlugins])
    mutate(`${API_URL}/modules`)
    mutate(`${API_URL}/projects`)
    mutate(`${API_URL}/info`)
    dispatch(setSelectedPlugin(null))
    dispatch(setSelectedChannels(null))
    dispatch(setActiveModule({ key: '', name: '' }))
    dispatch(setSelectedDirectory({ key: '', title: '' }))
    dispatch(setExpandedDirs(['dir-0']))
    dispatch(resetTabs())
    dispatchEvent('onChangeProject')
  }

  const onClickHandler = ({ key } : MenuInfo) => {
    switch (key) {
      case 'conf-1':
        setIsNewModal(true)
        break
      case 'conf-2':
        setIsOpenModal(true)
        break
      case 'conf-3':
        saveProjectHandler(currentProject)
        break
      case 'conf-4':
        setIsSaveAsModal(true)
        break
      case 'conf-5':
        importHandler()
        break
      case 'conf-6':
        exportHandler()
        break
    }
  }

  const loadProjectHandler = () => {
    if (selectedProject) {
      setIsLoading(true)

      loadProject(selectedProject)
        .then((res) => {
          if (res.ok) resetProjectData()
        })
        .catch(err => console.error(err))
        .finally(() => {
          setIsLoading(false)
          setIsOpenModal(false)
        })
    } else {
      setIsOpenModal(false)
    }
  }

  const saveProjectHandler = async (name: string, silent: boolean = false) => {
    setIsLoading(true)

    if (!silent) {
      messageApi.open({
        key: 'updatable',
        type: 'loading',
        content: 'Сохранение...',
      })
    }

    try {
      const res = await saveProject(name)

      if (res.ok) {
        mutate(DIR_URL)
        mutate(`${API_URL}/projects`)

        if (!silent) {
          messageApi.open({
            key: 'updatable',
            type: 'success',
            content: 'Конфигурация сохранена.',
            duration: 5,
            onClick: () => messageApi.destroy('updatable')
          })
        }
      } else {
        if (!silent)
          messageApi.destroy('updatable')
      }
    } catch (e) {
      console.error(e)
    }

    setIsSaveAsModal(false)
    setIsLoading(false)
  }

  const newProjectHandler = async (name: string) => {
    setIsLoading(true)

    try {
      await newProject(name)
      const res = await loadProject(name)
      if (res.ok) resetProjectData()
    } catch (e) {
      console.error(e)
    }

    setIsLoading(false)
    setIsNewModal(false)
  }

  const exportHandler = async () => {
    try {
      await saveProjectHandler(currentProject, true)
      const res = await exportProject(currentProject)
      const hash = getSHA256String(res.data)

      if (hash === res.hash) {
        fileSaver(ASCIIToUTF8(atob(res.data)), currentProject)
      }
      else {
        console.error('Hash is not valid')
        message.error({
          content: 'Ошибка экспорта файла',
          key: 'exportError',
          duration: 10,
          onClick: () => message.destroy('exportError')
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const importHandler = () => {
    fileUploader('.dcs3project')
      .then(async (res) => {
        const text = String(res.data)
        const textBytes = new TextEncoder().encode(text)
        const binString = Array.from(textBytes, (byte) =>
          String.fromCodePoint(byte),
        ).join('')
        const textEncoded = btoa(binString)

        if (text) {
          importProject({
            name: res.name,
            hash: getSHA256String(textEncoded),
            data: textEncoded
          })
            .then(() => {
              mutate(`${API_URL}/projects`)

              message.success({
                content: 'Проект загружен!',
                key: 'importProject',
                duration: 5,
                onClick: () => message.destroy('importProject')
              })
            })
            .catch(e => console.error(e))
        } else {
          message.error({
            content: 'Пустой файл',
            key: 'importError',
            duration: 10,
            onClick: () => message.destroy('importError')
          })
        }
      })
  }

  return (
    <>
      {contextHolder}
      <AppDropdownMenu
        title={'Конфигурация'}
        items={configItems}
        onClick={onClickHandler}
      />

      <Modal
        title="Открыть проект"
        open={isOpenModal}
        confirmLoading={isLoading}
        onOk={loadProjectHandler}
        onCancel={() => setIsOpenModal(false)}
      >
        <Select
          defaultValue={currentProject}
          style={{ width: 320 }}
          onChange={(val) => setSelectedProject(val)}
        >
          {projects?.map((prj => (
            <Option value={prj} key={prj}>{prj}</Option>
          )))}
        </Select>
      </Modal>

      <Modal
        title="Сохранить проект как..."
        open={isSaveAsModal}
        confirmLoading={isLoading}
        cancelText={'Отмена'}
        okText={'Сохранить'}
        onOk={() => saveProjectHandler(newProjectName)}
        onCancel={() => {
          setIsSaveAsModal(false)
          form.setFieldValue('projectName', currentProject)
          form.validateFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
        >
          <Form.Item
            label="Имя проекта"
            name="projectName"
            initialValue={newProjectName}
            rules={[{ required: true, message: 'Введите имя проекта' }]}
          >
            <Input
              onChange={e => setNewProjectName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Новая конфигурация"
        open={isNewModal}
        confirmLoading={isLoading}
        cancelText={'Отмена'}
        okText={'Создать'}
        onOk={() => newProjectHandler(newProjectName)}
        onCancel={() => {
          setIsNewModal(false)
          setNewProjectName(currentProject)
        }}
      >
        <Form
          layout={'vertical'}
          initialValues={{ projectNewName: '.dcs3project' }}
        >
          <Form.Item
            label="Имя проекта"
            name="projectNewName"
            rules={[{ required: true, message: 'Введите имя проекта' }]}
          >
            <Input
              onChange={e => setNewProjectName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
