export const commands = [
  {
    label: 'Получить структуру',
    command: 'create_struct'
  },
  {
    label: 'Отобразить структуру',
    command: 'create_channels'
  },
]
