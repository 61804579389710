import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelsTableData } from 'entities/channel'
import keyToId from 'shared/lib/key-to-id'
import { omitObject } from 'shared/lib/omit-object'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import type { ModbusRequest, ModbusRTUSettingsType } from '../types'

type BindingRequestsProps = {
  channels: { key: string, name: string }[]
  isOpen: boolean
  requestId: number | null
  onClose: () => void
}

export const BindingRequests: FC<BindingRequestsProps> = ({ channels, isOpen, requestId, onClose }) => {
  const { state: { settings } } = useContext<ModuleContextType<ModbusRTUSettingsType>>(ModuleContext)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const onSaveHandler = async (data: Partial<ChannelsTableData>[]) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      setLoading(true)

      set.requests = set.requests.map((r: ModbusRequest) => (
        (r.id === requestId)
          ? { ...r, channels: data.map(ch => keyToId(String(ch.key))) }
          : r
      ))

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          onClose()
        })
    }
  }

  return (
    <BindingChannels
      boundChannels={channels}
      isLoading={loading}
      isOpen={isOpen}
      onSave={onSaveHandler}
      onClose={onClose}
    />
  )
}
