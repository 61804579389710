import React, { useState } from 'react'
import type { FC, Key } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { omitObject } from 'shared/lib/omit-object'
import { DeleteModal } from 'shared/ui/modals/delete-modal'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'

type DeleteObjectProps = {
  settings: any
  keys: Key[]
  onDelete?: () => void
}

export const DeleteObject: FC<DeleteObjectProps> = ({ settings, keys, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const onDeleteHandler = () => {
    if (settings && keys.length) {
      setLoading(true)
      const set = omitObject(settings, ['uid'])

      set.config.objects_list = set.config.objects_list.filter((item: any) => (
        !keys.includes('object-' + item.ind)
      ))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          if (onDelete) onDelete()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        disabled={!keys.length}
        onClick={() => setIsModalOpen(true)}
      />

      <DeleteModal
        title="Удаление объекта"
        text={'Удалить объекты'}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={onDeleteHandler}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
