export function generateInt64(): number {
  const state = new Uint32Array(2)
  const output = new BigUint64Array(state.buffer)
  const t = performance.now()
  const a = Math.floor(t * 0x100000000) ^ Math.floor(t)

  state[0] = a ^ Math.floor(Math.random() * 0x100000000)
  state[1] = ~a ^ Math.floor(Math.random() * 0x100000000)

  return +output[0].toString().slice(0, -1)
}
