import type { ColumnsType } from 'antd/es/table'
import type { Channel } from 'entities/channel'
import type { ModbusRequest } from '../types'

export const updateTableData = (requests: ModbusRequest[], prevData: ModbusRequest[], channels: Channel[] | undefined) => {
  const channelsColumns: ColumnsType<ModbusRequest> = []
  const requestsData = requests.map(r => {
    const data: any = {
      key: 'req-' + r.id,
      id: r.id,
      event: r.event,
      period: r.period,
      timeout: r.timeout,
      unit: r.unit,
      addr: r.addr,
      function: r.function,
      bytes_order: r.bytes_order,
      channels: r.channels
    }

    if (r.channels.length) {
      r.channels.forEach((ch, idx) => {
        if (!channelsColumns[idx]) {
          channelsColumns.push({
            title: 'Канал ' + idx,
            dataIndex: 'channelColumn-' + idx,
            key: 'channelColumn-' + idx,
            width: 160,
          })
        }

        const channel = channels && channels.find(item => item.uid === ch)
        data['channelColumn-' + idx] = channel ? channel.name : ''
      })
    }

    return data
  })

  return { channelsColumns, requestsData }
}
