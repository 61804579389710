import React, { useContext } from 'react'
import type { FC } from 'react'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddMQTTTopic } from 'features/mqtt/add-topic'
import { RequestsTable } from 'shared/ui/table/requests-table'
import type { MQTTSettingsType } from '../types'
import { COLUMNS } from '../config'
import { MQTTForm } from './mqtt-form'

export const MQTT: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<MQTTSettingsType>>(ModuleContext)

  return (
    <>
      <div className={'content-wrapper'}>
        <MQTTForm
          settings={settings}
          reducerDispatch={reducerDispatch}
          isChanged={isChanged}
        />
      </div>

      <RequestsTable
        settings={settings}
        reducerDispatch={reducerDispatch}
        itemsName={'topics_name'}
        itemPrefix={'topic-'}
        deleteText={'Удалить топик'}
        isChanged={isChanged}
        columns={COLUMNS}
        headerComponents={[<AddMQTTTopic key={'AddMQTTTopic'} />]}
      />
    </>
  )
}
