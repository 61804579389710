import React, { FC } from 'react'
import { BlockHeaderProps } from './types'
import css from './BlockHeader.module.scss'

const BlockHeader: FC<BlockHeaderProps> = ({ actionButtons, closeButton }) => {
  return (
    <div className={css.BlockHeader}>
      <div className={css.BlockHeader_actions}>
        {actionButtons?.map(item => (item))}
      </div>

      {closeButton}
    </div>
  )
}

export default BlockHeader
