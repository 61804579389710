import React, { useEffect, useState } from 'react'
import type { FC, Key } from 'react'
import cn from 'classnames'
import useSWRImmutable from 'swr/immutable'
import { Tree, Spin } from 'antd'
import type { DataNode, EventDataNode } from 'antd/es/tree'
import { DownOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'app/store'
import type { ContentComponents } from 'app/providers/content-provider'
import { addTab } from 'entities/tab'
import {
  getTreeData, selectModules,
  selectPlugins,
  selectTypes,
  setActiveModule,
  setSelectedPlugin
} from 'entities/module'
import type { TreeDataType } from 'entities/module'
import { getModules, getPlugins, getTypes } from 'shared/api/modules'
import multiFetcher from 'shared/lib/api/multi-fetcher'
import { subscribeEvent, unsubscribeEvent } from 'shared/lib/custom-events'
import { API_URL } from 'shared/config'
import Header from './header'
import css from '../Plugins.module.scss'

export const ModulesTree: FC = () => {
  const dispatch = useAppDispatch()
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const types = useAppSelector(selectTypes)
  const plugins = useAppSelector(selectPlugins)
  const modules = useAppSelector(selectModules)
  const { isLoading } = useSWRImmutable([getTypes, getPlugins], multiFetcher)
  const { isLoading: isModLoading, isValidating } = useSWRImmutable(`${API_URL}/modules`, getModules)

  useEffect(() => {
    subscribeEvent('onChangeProject', () => setSelectedKeys([]))

    return unsubscribeEvent('onChangeProject', () => setSelectedKeys([]))
  }, [])

  useEffect(() => {
    if (types && plugins && modules) {
      setTreeData(() => getTreeData(types, plugins, modules))
    }
  }, [types, plugins, modules])

  const selectModule = (keys: Key[], node: EventDataNode<TreeDataType>) => {
    const activePlugin = node.name ?? node.pluginName

    if (activePlugin) {
      dispatch(setSelectedPlugin(activePlugin))
      setSelectedKeys(prev => keys.length ? keys : prev)
    }

    if (node.pluginName) {
      dispatch(setActiveModule({
        key: String(node.key),
        name: String(node.title)
      }))

      dispatch(addTab({
        key: node.key as string,
        label: node.title as string,
        component: node.pluginName as ContentComponents,
        isActive: true
      }))
    }
  }

  return (
    <div className={cn([css.Plugins, 'bd-left'])}>
      <Header modules={modules}/>

      <div className={css.Plugins_content}>
        <Spin spinning={isLoading || isModLoading || isValidating}>
          {!!treeData.length && (
            <Tree
              showLine
              switcherIcon={<DownOutlined />}
              defaultExpandAll
              treeData={treeData}
              selectedKeys={selectedKeys}
              onSelect={(selectedKeys, e) => selectModule(selectedKeys, e.node)}
            />
          )}
        </Spin>
      </div>
    </div>
  )
}
