import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import type { FC, Key } from 'react'
import cn from 'classnames'
import { Modal } from 'antd'
import type { SorterResult } from 'antd/es/table/interface'
import { useAppSelector } from 'app/store'
import { selectChannelsLength } from 'entities/project'
import { selectDirs } from 'entities/directory'
import type { DirTreeData } from 'entities/directory'
import { createChannelsArr, generateTableData } from 'entities/channel'
import type { ChannelItem, ChannelsData, ChannelsTableData } from 'entities/channel'
import { Directories } from 'shared/ui/directories'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import { useChannels } from 'shared/hooks/use-channels'
import { COLUMNS } from '../config'
import { bindingReducer, bindingInitState } from '../model/binding-reducer'
import { setChannelsArr, setSelectedDirectory } from '../model/binding-reducer'
import { BindingContext } from '../model/binding-context'
import { BoundTable } from './bound-table'
import css from '../BindingChannels.module.scss'

type BindingChannelsProps = {
  boundChannels: ChannelItem[]
  isLoading?: boolean
  isOpen: boolean
  isMultiple?: boolean
  onSave: (data: Partial<ChannelsTableData>[]) => void
  onClose: () => void
  afterClose?: () => void
}

export const BindingChannels: FC<BindingChannelsProps> = ({
  boundChannels,
  isLoading,
  isOpen,
  isMultiple = true,
  onSave,
  onClose,
  afterClose
}) => {
  const [state, reducerDispatch] = useReducer(bindingReducer, bindingInitState)
  const [tableData, setTableData] = useState<ChannelsData | null>({ query: null, items: [] })
  const [bindTableData, setBindTableData] = useState<ChannelItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])
  const [selectedBoundRows, setSelectedBoundRows] = useState<Key[] | null>(null)
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(['dir-0'])
  const [totalChannels, setTotalChannels] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortField, setSortField] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(!!isLoading)
  const [tableLoading, setTableLoading] = useState<boolean>(!!isLoading)
  const dirs = useAppSelector(selectDirs)
  const channelsLength = useAppSelector(selectChannelsLength)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const { data, isLoading: isChannelsLoading } = useChannels(state.channelsArr, 0, 0, sortField, sortDirection, 0)

  useEffect(() => {
    if (data && data.channels.length) {
      setTableData((prev) => ({
        query: JSON.stringify(state.channelsArr),
        items: generateTableData(prev, { query: JSON.stringify(state.channelsArr), items: data.channels }) || []
      }))

      setTotalChannels(data.search_size)
    }
  }, [data])

  useEffect(() => {
    setBindTableData(boundChannels)
    setSelectedRowsKeys(() => boundChannels.map((ch) => ch.key))
  }, [boundChannels])

  useEffect(() => {
    if (!state.channelsArr) {
      setLoading(false)
      setTableData({ query: null, items: [] })
    }
  }, [state.channelsArr])

  useEffect(() => {
    setCurrentPage(1)
    setTotalChannels(0)
  }, [state.selectedDirectory])

  useEffect(() => {
    setLoading(!!isLoading)
  }, [isLoading])

  useEffect(() => {
    setTableLoading(isChannelsLoading)
  }, [isChannelsLoading])

  useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  const onSelectHandler = useCallback((dir: DirTreeData) => {
    reducerDispatch(setChannelsArr(createChannelsArr(dir, dir.key === 'dir-0', channelsLength)))
    reducerDispatch(setSelectedDirectory({ key: dir.key, title: dir.title }))
  }, [])

  const onSelectChannel = (selected: boolean, record: ChannelsTableData) => {
    setSelectedRowsKeys(prev => {
      if (selected) {
        if (isMultiple) {
          prev = prev.concat([record.key])
          onBindHandler(bindTableData.concat([{ key: record.key || '', name: record.name }]))
        } else {
          prev = [record.key]
          onBindHandler([{ key: record.key, name: record.name }])
        }
      }
      else {
        prev = prev.filter(item => item !== record.key)
        onBindHandler(bindTableData.filter(item => item.key !== record.key))
      }

      return prev
    })
  }

  const onMultiSelectChannel = (selected: boolean, changeRows: ChannelsTableData[]) => {
    setSelectedRowsKeys(prev => {
      if (selected) {
        prev = prev.concat(changeRows.map(cr => cr.key))
        onBindHandler(bindTableData.concat(changeRows.map(cr => ({ key: cr.key, name: cr.name }))))
      }
      else {
        prev = prev.filter(item => !changeRows.find(cr => cr.key === item))
        onBindHandler(bindTableData.filter(item => !changeRows.find(cr => cr.key === item.key)))
      }

      return prev
    })
  }

  const onAllSelectChannel = (selected: boolean, selectedRows: ChannelsTableData[]) => {
    if (selected && tableData) {
      (totalChannels > pageSize)
        ? setSelectedAndBound(tableData.items)
        : setSelectedAndBound(selectedRows)
    } else {
      setSelectedRowsKeys([])
      setBindTableData([])
    }
  }

  const setSelectedAndBound = (channels: ChannelsTableData[]) => {
    const selected: Key[] = []
    const bounded: ChannelItem[] = []

    setTableLoading(true)

    channels.forEach(item => {
      selected.push(item.key)
      bounded.push({
        key: item.key,
        name: item.name
      })
    })

    setSelectedRowsKeys(selected)
    onBindHandler(bounded).finally(() => setTableLoading(false))
  }

  const onBindHandler = (rows: ChannelItem[]) => {
    return new Promise(res => {
      if (tableData && rows) {
        setBindTableData(() => {
          return rows.map(item => ({
            key: item.key,
            name: item.name,
          }))
        })
      }
      res('')
    })
  }

  const onUnbindHandler = () => {
    if (tableData && selectedBoundRows) {
      setBindTableData((prev) => {
        const tableRows: Key[] = []
        const updated = prev.filter(item => {
          if (!selectedBoundRows.includes(item.key!))
            tableRows.push(item.key!)

          return !selectedBoundRows.includes(item.key!)
        })

        setSelectedRowsKeys(tableRows)

        return updated
      })

      setSelectedBoundRows(null)
    }
  }

  const onSortHandler = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    if (!Array.isArray(sorter)) {
      if (sorter.column) {
        setSortField(String(sorter.field))
        setSortDirection((sorter.order === 'ascend') ? 1 : 0)
      } else {
        setSortField('')
      }
    }
  }

  return (
    <BindingContext.Provider value={{ state, reducerDispatch }}>
      <Modal
        title={'Привязка каналов'}
        open={isModalOpen}
        confirmLoading={loading}
        cancelText={'Отмена'}
        okText={'Добавить'}
        forceRender
        width={1300}
        styles={{ body: { height: '500px' } }}
        onOk={async () => {
          await onSave(bindTableData)
          onClose()
        }}
        onCancel={onClose}
        afterClose={afterClose}
      >
        <div
          id={'binding-channels'}
          className={cn([css.BindingChannels])}
          ref={contentRef}
        >
          <div className={cn([css.BindingChannels_directories, 'bd-bottom bd-left bd-right bd-top'])}>
            <Directories
              dirs={dirs}
              expandedKeys={expandedKeys}
              selectedDirectory={state.selectedDirectory}
              onSelect={onSelectHandler}
              onExpand={keys => setExpandedKeys(keys)}
            />
          </div>

          <div className={cn([css.BindingChannels_table, { 'bd-bottom': tableData?.items.length }])}>
            <TableResponsive
              columns={COLUMNS}
              dataSource={tableData?.items}
              parentRef={contentRef}
              loading={tableLoading}
              showSorterTooltip={false}
              pagination={{
                size: 'small',
                position: ['bottomRight'],
                current: currentPage,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                hideOnSinglePage: totalChannels <= 10,
                showSizeChanger: true,
                total: totalChannels,
                onChange: (page) => setCurrentPage(page),
                onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
              }}
              rowSelection={{
                type: isMultiple ? 'checkbox' : 'radio',
                selectedRowKeys: selectedRowsKeys || undefined,
                onSelect: (record, selected) => {
                  onSelectChannel(selected, record)
                },
                onSelectMultiple: (selected, _, changeRows) => {
                  onMultiSelectChannel(selected, changeRows)
                },
                onSelectAll: (selected, selectedRows) => {
                  onAllSelectChannel(selected, selectedRows)
                },
                getCheckboxProps: (record: ChannelsTableData) => ({
                  name: String(record.key),
                }),
              }}
              onChange={(pagination, filters, sorter, extra) => {
                if (extra.action === 'sort')
                  onSortHandler(sorter)
              }}
            />
          </div>

          <div className={cn([css.BindingChannels_addedTable, { 'bd-bottom': bindTableData.length }, 'bd-left', 'bd-right', 'bd-top'])}>
            <BoundTable
              data={bindTableData}
              parentRef={contentRef}
              isMultiple={isMultiple}
              onClick={onUnbindHandler}
              onChange={(selectedRowKeys: React.Key[]) => {
                setSelectedBoundRows(selectedRowKeys)
              }}
              onDragEnd={setBindTableData}
            />
          </div>
        </div>
      </Modal>
    </BindingContext.Provider>
  )
}
