import React from 'react'
import type { Dispatch } from 'react'
import type { BindingReducerAction, BindingReducerState } from './binding-reducer'

export type BindingContextType = {
  state: BindingReducerState
  reducerDispatch: Dispatch<BindingReducerAction>
}

export const BindingContext = React.createContext<BindingContextType>(undefined as any)
