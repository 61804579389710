import React, { useContext } from 'react'
import type { FC } from 'react'
import { Space } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddRequest } from 'features/module/database/add-request'
import { EditRequest } from 'features/module/database/edit-request'
import { RequestsTable } from 'shared/ui/table/requests-table'
import { COLUMNS } from '../config'
import type { DatabaseSettingsType } from '../types'
import { DbForm } from './db-form'
import css from '../Database.module.scss'

export const DBPlugin: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<DatabaseSettingsType>>(ModuleContext)

  return (
    <div className={css.Database}>
      <div className={'content-wrapper'}>
        <DbForm />
      </div>

      <RequestsTable<DatabaseSettingsType>
        columns={COLUMNS}
        settings={settings}
        reducerDispatch={reducerDispatch}
        isChanged={isChanged}
        itemsName={'requests'}
        itemPrefix={'req-'}
        deleteText={'Удалить запрос'}
        rowSelectionWidth={66}
        headerComponents={[<AddRequest key={'AddRequest'}/>]}
        renderSelectionCell={(checked, record, index, originNode) => (
          <div style={{ display: 'flex' }}>
            <Space>
              {originNode}
              <EditRequest
                settings={settings}
                requestId={record.id}
              />
            </Space>
          </div>
        )}
      />
    </div>
  )
}
