import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddHandler } from 'features/notifications/add-handler'
import { AddSubscriber } from 'features/notifications/add-subscriber'
import { AddMessage } from 'features/notifications/add-message'
import { DeleteSubscriber } from 'features/notifications/delete-subscriber'
import { DeleteMessage } from 'features/notifications/delete-message'
import { RequestsTable } from 'shared/ui/table/requests-table'
import { NestedTable } from 'shared/ui/table/nested-table'
import type {
  NotificationsSettingsType, NotificationsSubscriberObject,
  MessagesSubscriberObject, NotificationsHandler,
} from '../types'
import { COLUMNS, COLUMNS_MESSAGES, COLUMNS_SUBSCRIBERS } from '../config'
import { NotificationForm } from './notification-form'

export const Notifications: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<NotificationsSettingsType>>(ModuleContext)
  const [subscribersTableObject, setSubscribersTableObject] = useState<NotificationsSubscriberObject>({})
  const [messagesTableObject, setMessagesTableObject] = useState<MessagesSubscriberObject>({})

  useEffect(() => {
    if (settings) {
      const subscribers: NotificationsSubscriberObject = {}
      const messages: MessagesSubscriberObject = {}

      settings.handlers.forEach((handler) => {
        if (handler.subscribers.length) {
          subscribers[`handler-${handler.id}`] = handler.subscribers.map((subscriber) => ({
            key: `subscriber-${handler.id}-${subscriber.id}`,
            ...subscriber
          }))
        }

        if (handler.messages.length) {
          messages[`handler-${handler.id}`] = handler.messages.map((message) => ({
            key: `message-${handler.id}-${message.id}`,
            ...message
          }))
        }
      })

      setSubscribersTableObject(subscribers)
      setMessagesTableObject(messages)
    }
  }, [settings])

  const onChangeValueHandler = (record: any, changedValue: any, tableName: 'subscribers' | 'messages') => {
    const [number, handlerId] = record.key.split('-').reverse()

    reducerDispatch(setSettings({
      ...settings,
      handlers: settings.handlers.map((handler: NotificationsHandler) => (
        (handler.id === +handlerId) ? ({
          ...handler,
          [tableName]: handler[tableName].map((item) => (
            (item.id === +number) ? ({ ...item, ...changedValue }) : item
          ))
        }) : handler
      ))
    }))
    reducerDispatch(setIsChanged(true))
  }

  return (
    <>
      <NotificationForm
        settings={settings}
        reducerDispatch={reducerDispatch}
        isChanged={isChanged}
      />

      <RequestsTable
        columns={COLUMNS}
        settings={settings}
        reducerDispatch={reducerDispatch}
        itemsName={'handlers'}
        itemPrefix={'handler-'}
        deleteText={'Удалить обработчик'}
        isChanged={isChanged}
        headerComponents={[<AddHandler key={'addNotificationHandler'}/>]}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <NestedTable
                  columns={COLUMNS_SUBSCRIBERS}
                  tableData={subscribersTableObject[record.key]}
                  nestedTitle={'Абоненты'}
                  parentId={record.id}
                  getDeleteElement={() => DeleteSubscriber}
                  actions={[<AddSubscriber key={'add-subscriber'} handlerId={record.id} />]}
                  onChangeValue={(record, changedValue) => onChangeValueHandler(record, changedValue, 'subscribers')}
                />

                <NestedTable
                  columns={COLUMNS_MESSAGES}
                  tableData={messagesTableObject[record.key]}
                  nestedTitle={'Сообщения'}
                  parentId={record.id}
                  getDeleteElement={() => DeleteMessage}
                  actions={[<AddMessage key={'add-message'} handlerId={record.id} />]}
                  onChangeValue={(record, changedValue) => onChangeValueHandler(record, changedValue, 'messages')}
                />
              </>
            )
          },
        }}
      />
    </>
  )
}
