import React, { FC, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import type { VarItem } from 'shared/types'
import { formValidate } from 'shared/lib/form-validate'
import type { VarActionProps } from 'shared/ui/table/variables-table'
import { EditModal } from 'shared/ui/modals/edit-modal'

export const EditVar: FC<VarActionProps> = ({ name, variables, varNameField, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (name)
      form.setFieldValue('name', name)
  }, [name])

  const onEditFn = () => {
    if (variables) {
      const newVars = variables.map((v: VarItem) => (
        (v[varNameField] === name) ? { ...v, [varNameField]: form.getFieldValue('name') } : v
      ))

      setLoading(true)

      onEdit(newVars).then(() => {
        setLoading(false)
        setIsModalOpen(false)
      })
    }
  }

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>Редактировать</div>

      <EditModal
        title="переменной"
        open={isModalOpen}
        confirmLoading={loading}
        onSave={() => formValidate(form, onEditFn)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onEditFn)
          }}
        >
          <Form.Item
            label="Имя переменной"
            name="name"
            rules={[{ required: true, message: 'Введите имя переменной' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </EditModal>
    </>
  )
}
