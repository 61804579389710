import React, { FC } from 'react'
import { Row, Space } from 'antd'
import cn from 'classnames'
import { Configuration } from 'features/app/configuration'
import { AppView } from 'features/app/app-view'
import { About } from 'features/app/about'
import css from './Header.module.scss'

export const Header: FC = () => {
  return (
    <div className={cn([css.Header, 'bd-bottom'])}>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Row>
          <div className={cn([css.Header__menu, 'bd-top'])}>
            <Space>
              <img src="/min.svg" alt="logo" width={24} />
              <Configuration />
              <AppView />
              <About />
            </Space>
          </div>
        </Row>
      </Space>
    </div>
  )
}
