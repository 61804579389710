import React, { FC } from 'react'
import { Button } from 'antd'
import { DragOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { useDraggable } from '@dnd-kit/core'
import type { ChannelItem } from 'entities/channel'

interface CellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  'data-row-key': string
  dataIndex: string
  record: ChannelItem
  isMultiple?: boolean
}

export const SortableCell: FC<CellProps> = ({ children, dataIndex, record, isMultiple = true, ...restProps }) => {
  const { setActivatorNodeRef } = useDraggable({
    id: record ? String(record.key) : '',
  })
  const { listeners } = useSortable({
    id: record ? String(record.key) : '',
  })

  if (dataIndex === 'name')
    return (
      <td {...restProps}>
        {children}

        {isMultiple && (
          <Button
            icon={<DragOutlined />}
            size={'small'}
            ref={setActivatorNodeRef}
            {...listeners}
          />
        )}
      </td>
    )
  else
    return <td {...restProps}>{children}</td>
}
