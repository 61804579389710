import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ProjectState } from '../types'

const initialState: ProjectState = {
  current: '',
  existing: [],
  channelsLength: 0,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setStructure: (state, action: PayloadAction<ProjectState>) => {
      state.current = action.payload.current
      state.existing = action.payload.existing
    },
    setChannelsLength: (state, action: PayloadAction<number>) => {
      state.channelsLength = action.payload
    },
  },
})

export const projectReducer = projectSlice.reducer
export const { setStructure, setChannelsLength } = projectSlice.actions
export const selectCurrentProject = (state: RootState) => state.project.current
export const selectList = (state: RootState) => state.project.existing
export const selectChannelsLength = (state: RootState) => state.project.channelsLength
