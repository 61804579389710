import type { ModuleSettings } from 'entities/module'
import { createObjectsArray } from 'shared/lib/create-objects-array'

export const createSearchChannelsArr = (settings: ModuleSettings<any>, selectedFolderId: number): string[] | null => {
  const objects = createObjectsArray(settings, selectedFolderId)

  const arr = settings.config.objects_list.reduce<string[]>((acc, curr) => {
    const object = curr.object

    if (objects.includes(curr.ind) && object.core_channel_uid)
      acc.push(curr.object.core_channel_uid)

    return acc
  }, [])

  return arr.length ? arr : null
}
