import React, { FC, useContext } from 'react'
import { Checkbox, Space } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { InputTimeout } from './input-timeout'
import type { LuaSettingsType } from '../types'

export const TopForm: FC<{ checked: boolean }> = ({ checked }) => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<LuaSettingsType>>(ModuleContext)

  const onChangeCheckbox = () => {
    if (settings) {
      reducerDispatch(setSettings({
        ...settings,
        periodic: !settings.periodic
      }))
      reducerDispatch(setIsChanged(true))
    }
  }

  return (
    <Space>
      <InputTimeout />

      <div>
        Выполнять периодически:&nbsp;

        <Checkbox
          checked={checked}
          onChange={onChangeCheckbox}
        />
      </div>
    </Space>
  )
}
