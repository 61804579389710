import React, { FC, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import type { EditChannelType, ActionType, EditStateType } from 'entities/channel'
import { changeChannels, changeChannelsState } from 'shared/api/channels'
import { subscribeEvent, unsubscribeEvent } from 'shared/lib/custom-events'

type ChangeChannelProps = {
  items: EditChannelType[],
  onChange: (loading: boolean, type: ActionType) => void
}

export const ChangeChannel: FC<ChangeChannelProps> = ({ items, onChange }) => {
  const [isDisabled, setIsDisabled] = useState(!items.length)
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false)

  useEffect(() => {
    subscribeEvent('channel:onPressEnter', () => setIsEnterPressed(true))
    return () => unsubscribeEvent('channel:onPressEnter', () => setIsEnterPressed(true))
  }, [])

  useEffect(() => {
    setIsDisabled(!items.length)
  }, [items])

  useEffect(() => {
    if (isEnterPressed) onSave()
  }, [isEnterPressed])

  const onSave = async () => {
    const fields: EditChannelType[] = []
    const state: EditStateType[] = []

    items.map(item => {
      fields.push({
        uid: item.uid,
        name: item.name,
        type: item.type,
        dir: item.dir,
        group: item.group,
        description: item.description,
      })

      if (item.isValueChanged && !item.isQualityChanged) {
        state.push({
          uid: item.uid,
          value: item.value,
          quality: 1
        })
      } else if (item.isValueChanged || item.isQualityChanged) {
        state.push({
          uid: item.uid,
          value: item.value,
          quality: item.quality
        })
      }
    })

    onChange(true, 'change')
    setIsDisabled(true)

    try {
      await changeChannels(fields)
    } catch (err) {
      console.error(err)
    }

    if (state.length) {
      try {
        await changeChannelsState(state)
      } catch (err) {
        console.error(err)
      }
    }

    onChange(false, 'change')

    setIsEnterPressed(false)
  }

  return (
    <>
      <Button
        type="primary"
        icon={<SaveOutlined />}
        size={'small'}
        disabled={isDisabled}
        onClick={() => items.length && onSave()}
      />
    </>
  )
}
