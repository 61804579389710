import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Space } from 'antd'
import { executeModule, SETTINGS_URL } from 'shared/api/modules'

type ModuleCommandsType = {
  id: string
  commands: {
    label: string
    command: string
  }[]
}

export const ModuleCommands: FC<ModuleCommandsType> = ({ id, commands }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  return (
    <Space>
      {commands.map(c => (
        <Button
          key={c.command}
          type="primary"
          size={'small'}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true)

            executeModule(id, c.command)
              .then(() => mutate(`${SETTINGS_URL}${id}`))
              .catch((err) => console.error(err))
              .finally(() => setIsLoading(false))
          }}
        >
          {c.label}
        </Button>
      ))}
    </Space>
  )
}
