import type { CustomColumn } from 'shared/ui/table/table-responsive'

export const COLUMNS: CustomColumn[] = [
  {
    title: 'Тема письма',
    dataIndex: 'email_subject',
    key: 'email_subject',
    editable: true,
  },
  {
    title: 'Канал параметров',
    dataIndex: 'channel_parameters',
    key: 'channel_parameters',
    isChannel: true,
    width: 300,
    render: (text: string) => text ?? 'Not found',
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
))

export const COLUMNS_SUBSCRIBERS: CustomColumn[] = [
  {
    title: 'Имя',
    dataIndex: 'subscriber_name',
    key: 'subscriber_name',
    editable: true,
  },
  {
    title: 'Email',
    dataIndex: 'subscriber_email',
    key: 'subscriber_email',
    editable: true,
    width: 400,
  },
  {
    title: 'Номер',
    dataIndex: 'subscriber_number',
    key: 'subscriber_number',
    editable: true,
    width: 200,
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
))

export const COLUMNS_MESSAGES: CustomColumn[] = [
  {
    title: 'Значение канала',
    dataIndex: 'parameters',
    key: 'parameters',
    width: 140,
    editable: true,
  },
  {
    title: 'Текст сообщения',
    dataIndex: 'message',
    key: 'message',
    editable: true,
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
))
