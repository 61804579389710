import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { FolderAddOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import type { DirTreeData } from 'entities/directory'
import { createDirectory, DIR_URL } from 'shared/api/directories'
import keyToId from 'shared/lib/key-to-id'
import { formValidate } from 'shared/lib/form-validate'
import { AddModal } from 'shared/ui/modals/add-modal'

type AddFields = {
  directoryName: string
}

export const AddDirectory: FC<{ selectedDirectory: DirTreeData | null }> = ({ selectedDirectory }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: AddFields) => {
    setLoading(true)

    if (selectedDirectory && selectedDirectory.key) {
      createDirectory(keyToId(selectedDirectory.key), values.directoryName)
        .then(() => {
          setIsModalOpen(false)
          mutate(DIR_URL)
          form.resetFields()
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<FolderAddOutlined />}
        size={'small'}
        onClick={() => (selectedDirectory && selectedDirectory.key !== '') && setIsModalOpen(true)}
      />

      <AddModal
        title="директории"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<AddFields>
            label="Имя директории"
            name="directoryName"
            rules={[{ required: true, message: 'Введите имя директории' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
