import React, { FC } from 'react'
import cn from 'classnames'
import { ShowContentTabs } from 'features/show-content-tabs'
import css from './Tabs.module.scss'

export const Tabs: FC = () => {
  return (
    <div className={cn([css.tabs, 'bd-bottom'])}>
      <ShowContentTabs />
    </div>
  )
}
