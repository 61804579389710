import { TabWithComponent } from '../types'

export const setActiveTab = (state: TabWithComponent[], tab: TabWithComponent | string) => {
  if (typeof tab === 'string') {
    return state.map(item => ({
      ...item,
      isActive: item.key === tab
    }))
  } else {
    return state.map(item => ({
      ...item,
      label: (tab.key === item.key) ? tab.label : item.label,
      isActive: tab.key === item.key
    }))
  }
}
