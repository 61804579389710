function subscribeEvent(eventName: string, listener: () => void) {
  document.addEventListener(eventName, listener)
}

function unsubscribeEvent(eventName: string, listener: () => void) {
  document.removeEventListener(eventName, listener)
}

function dispatchEvent(eventName: string) {
  const event = new CustomEvent(eventName)
  document.dispatchEvent(event)
}

export { dispatchEvent, subscribeEvent, unsubscribeEvent }
