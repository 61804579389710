import React, { FC, useEffect, useState, memo } from 'react'
import { Table } from 'antd'
import type { TablePaginationConfig } from 'antd'
import { TableContext } from 'shared/context/table-context'
import BlockHeader from 'shared/ui/block-header'
import { resizeObserverHandler } from '../model'
import { TableResponsiveProps } from '../types'
import css from '../TableResponsive.module.scss'

type TableComponentProps = TableResponsiveProps & {
  tableHeight: number
  size?: string
  pagination?: boolean | TablePaginationConfig
  isEditing: boolean
}

// Сохраняем текущее состояние таблицы во время редактирования
const TableComponent: FC<TableComponentProps> = memo(({ tableHeight, size = 'small', pagination = false,  ...props }) => (
  <Table
    scroll={{ y: tableHeight }}
    size={size}
    pagination={pagination}
    {...props}
  />
), (oldProps, newProps) => newProps.isEditing)

TableComponent.displayName = 'TableComponent'

export const TableResponsive: FC<TableResponsiveProps> = ({ titleComponents, ...props }) => {
  const [tableHeight, setTableHeight] = useState(0)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null

    if (props.parentRef.current && window.ResizeObserver) {
      resizeObserver = resizeObserverHandler(props.parentRef.current, (size) => {
        setTableHeight(prev => size || prev)
      })
    }

    return () => resizeObserver?.disconnect()
  }, [])

  return (
    <TableContext.Provider value={{ setIsEditing }}>
      <div className={css.TableResponsive}>
        {titleComponents?.length && (
          <BlockHeader actionButtons={titleComponents} />
        )}

        <TableComponent isEditing={isEditing} tableHeight={tableHeight} { ...props }/>
      </div>
    </TableContext.Provider>
  )
}
