import { ChannelsData, ChannelsTableData } from '../types'

export const updateTableData = (data: ChannelsData, newItem: ChannelsTableData) => {
  const newData = [...data.items]
  const index = newData.findIndex((item) => newItem.key === item.key)
  const item = newData[index]

  newData.splice(index, 1, {
    ...item,
    ...newItem,
  })

  return { ...data, items: newData }
}
