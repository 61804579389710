import { KeyboardEvent } from 'react'

export const filterNums = (e: KeyboardEvent) => {
  if (!(
    (e.code.includes('Digit') && !e.shiftKey)
    || (e.code.includes('Numpad') && e.code.length === 7)
    || e.code === 'Period'
    || e.key === '.'
  )) {
    e.preventDefault()
    return
  }
}
