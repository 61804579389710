import type { Channel } from 'entities/channel'
import type { ModbusRequest } from '../types'

export const getBoundChannels = (data: Channel[] | undefined, item: ModbusRequest) => {
  if (data) {
    return data.reduce<{ key: string, name: string }[]>((acc, curr) => {
      if (item.channels && item.channels.includes(curr.uid))
        acc.push({ key: 'channel-' + curr.uid, name: curr.name })

      return acc
    }, [])
  } else return []
}
