import React, { useEffect, useState } from 'react'
import type { FC, MutableRefObject, Key } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { UnbindChannel } from 'features/channel/unbind-channel'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import { COLUMNS_ADDED } from '../config'
import { SortableRow } from './sortable-row'
import { SortableCell } from './sortable-cell'

type BoundTableProps = {
  data: Partial<ChannelsTableData>[]
  parentRef: MutableRefObject<HTMLDivElement | null>
  isMultiple?: boolean
  onClick: () => void
  onChange: (selectedRowKeys: Key[]) => void
  onDragEnd: (data: ChannelItem[]) => void
}

export const BoundTable: FC<BoundTableProps> = ({ data, parentRef, isMultiple = true, onClick, onChange, onDragEnd }) => {
  const [tableData, setTableData] = useState<Partial<ChannelsTableData>[]>([])
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  )

  useEffect(() => {
    if (data)
      setTableData(data)
  }, [data])

  const onDragEndHandler = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id)
        const overIndex = prev.findIndex((i) => i.key === over?.id)
        const updated = arrayMove(prev, activeIndex, overIndex)

        onDragEnd(updated.map(item => ({
          key: item.key || '',
          name: item.name || ''
        })))

        return updated
      })
    }
  }

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndHandler}>
      <SortableContext
        items={tableData.map((i) => String(i.key))}
        strategy={verticalListSortingStrategy}
      >
        <TableResponsive
          columns={COLUMNS_ADDED}
          dataSource={tableData}
          parentRef={parentRef}
          titleComponents={[<UnbindChannel key={'UnbindChannel'} onClick={onClick} />]}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            pageSize: 100,
            showSizeChanger: false,
          }}
          components={{
            body: {
              row: (props: any) => <SortableRow {...props} />,
              cell: (props: any) => <SortableCell {...props} isMultiple={isMultiple} />,
            },
          }}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys: React.Key[], _, { type }) => {
              if (type !== 'all')
                onChange(selectedRowKeys)
            },
            onSelectAll: (selected) => {
              if (selected)
                onChange(tableData.map(item => item.key ?? ''))
              else
                onChange([])
            },
            getCheckboxProps: (record: ChannelsTableData) => ({
              name: String(record.key),
            }),
          }}
        />
      </SortableContext>
    </DndContext>
  )
}
