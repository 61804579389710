import React, { FC, useContext } from 'react'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddHTTPEndpoint } from 'features/module/http/add-endpoint'
import type { ServerSettingsType } from 'shared/ui/form/server-form/types'
import { RequestsTable } from 'shared/ui/table/requests-table'
import { ServerForm } from 'shared/ui/form/server-form'
import { COLUMNS } from '../config'

export const HttpServer: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<ServerSettingsType>>(ModuleContext)

  return (
    <>
      <div className={'content-wrapper'}>
        <ServerForm
          settings={settings}
          isChanged={isChanged}
          onChange={(field, val) => {
            reducerDispatch(setSettings({
              ...settings,
              [field]: val
            }))
            reducerDispatch(setIsChanged(true))
          }}
          onValidate={isValid => reducerDispatch(setIsChanged(isValid))}
        />
      </div>

      <RequestsTable<ServerSettingsType>
        settings={settings}
        reducerDispatch={reducerDispatch}
        itemsName={'nodes'}
        itemPrefix={'node-'}
        deleteText={'Удалить адрес'}
        isChanged={isChanged}
        columns={COLUMNS}
        headerComponents={[<AddHTTPEndpoint key={'AddHTTPEndpoint'} />]}
      />
    </>
  )
}
