import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { getBoundChannels } from 'widgets/plugins/modbus/model/get-bound-channels'
import { RequestsTable } from 'widgets/plugins/modbus/ui/requests-table'
import { BindingRequests } from 'widgets/plugins/modbus/ui/binding-requests'
import type { ModbusRTUSettingsType } from '../../types'
import { RtuForm } from './rtu-form'

export const ModbusRtu: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<ModbusRTUSettingsType>>(ModuleContext)
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [boundChannels, setBoundChannels] = useState<{ key: string, name: string }[]>([])

  return (
    <>
      <div className={'content-wrapper bd-bottom'}>
        <RtuForm />
      </div>

      <RequestsTable
        settings={settings}
        reducerDispatch={reducerDispatch}
        onBindingClick={(data, record) => {
          setIsModalOpen(true)
          setSelectedRequestId(record.id)
          setBoundChannels(() => getBoundChannels(data, record))
        }}
      />

      {selectedRequestId && (
        <BindingRequests
          channels={boundChannels}
          requestId={selectedRequestId}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setTimeout(() => setSelectedRequestId(null), 300)
          }}
        />
      )}
    </>
  )
}
