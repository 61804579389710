import React from 'react'
import type { FC } from 'react'
import useSWRImmutable from 'swr/immutable'
import { Col, List, Modal, Row, Space, Spin, Typography } from 'antd'
import { API_URL } from 'shared/config'
import { getCoreInfo } from 'shared/api/app'
import css from './About.module.scss'

const { Text } = Typography

type AboutModalProps = {
  isOpen: boolean
  onCancel: () => void
}

export const AboutModal: FC<AboutModalProps> = ({ isOpen, onCancel }) => {
  const { data, isLoading } = useSWRImmutable(
    isOpen ? `${API_URL}/info` : null,
    getCoreInfo
  )

  return (
    <Modal
      title="О программе"
      open={isOpen}
      cancelText={'Закрыть'}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
    >
      <Spin spinning={isLoading}>
        <div className={css.About}>
          <Space direction={'vertical'}>
            <Row gutter={20}>
              <Col>
                <Text strong>Имя: </Text>
                <Text>{data?.name}</Text>
              </Col>

              <Col>
                <Text strong>Поставщик: </Text>
                <Text>{data?.provider}</Text>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col>
                <Text strong>Описание: </Text>
                <Text>{data?.description}</Text>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col>
                <Text strong>Количество каналов: </Text>
                <Text>{data?.channels_count}</Text>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col>
                <Text strong>Количество модулей: </Text>
                <List
                  size="small"
                  bordered
                  grid={{ gutter: 10, column: 3 }}
                  dataSource={data ? Object.entries(data.modules_count) : []}
                  renderItem={(item) => <List.Item><Col>{`${item[0]}: ${item[1]}`}</Col></List.Item>}
                />
              </Col>
            </Row>

            <Row gutter={20}>
              <Col>
                <Text strong>Версия: </Text>
                <Text>{data?.release}</Text>
              </Col>

              <Col>
                <Text strong>Время старта: </Text>
                <Text>{data?.start_time}</Text>
              </Col>
            </Row>
          </Space>
        </div>
      </Spin>
    </Modal>
  )
}
