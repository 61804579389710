import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { FolderAddOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { WialonSettings } from 'widgets/plugins/wialon/types'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import { formValidate } from 'shared/lib/form-validate'
import { generateInt64 } from 'shared/lib/generate-int64'
import { AddModal } from 'shared/ui/modals/add-modal'

export const AddFolder: FC<{ folderId: number | null }> = ({ folderId }) => {
  const { state: { settings } } = useContext<ModuleContextType<WialonSettings>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: any) => {
    if (settings && folderId !== null) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.config.folders_list.push({
        ind: generateInt64(),
        folder: {
          label: values.directoryName,
          parent: folderId,
          objects: [],
          sub_folders: [],
        }
      })

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<FolderAddOutlined />}
        size={'small'}
        onClick={() => (folderId !== null) && setIsModalOpen(true)}
      />

      <AddModal
        title="директории"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item
            label="Имя директории"
            name="directoryName"
            rules={[{ required: true, message: 'Введите имя директории' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
