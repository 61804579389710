import { message } from 'antd'

export async function httpHandler(url: string, options?: RequestInit) {
  const res = await fetch(url, options)

  if (!res.ok) {
    const errorText = await res.text()
    const content = errorText ? errorText : res.statusText
    const key = 'err-' + Date.now()

    message.error({
      content,
      key,
      duration: 10,
      onClick: () => message.destroy(key)
    })

    throw new Error(content)
  }

  return res
}
