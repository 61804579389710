import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/store'
import { selectedChannels, setSelectedChannels } from 'entities/channel'
import keyToId from 'shared/lib/key-to-id'
import { deleteChannels } from 'shared/api/channels'
import { DIR_URL } from 'shared/api/directories'
import { DeleteModal } from 'shared/ui/modals/delete-modal'

export const DeleteChannel: FC<{ onDeleted: () => void }> = ({ onDeleted }) => {
  const dispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const checkedChannels = useAppSelector(selectedChannels)
  const { mutate } = useSWRConfig()

  const onDelete = () => {
    if (checkedChannels) {
      setLoading(true)

      deleteChannels(checkedChannels.map(cc => keyToId(String(cc))))
        .then(() => {
          setIsModalOpen(false)
          dispatch(setSelectedChannels(null))
          onDeleted()
          mutate(DIR_URL)
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        disabled={!checkedChannels || !checkedChannels.length}
        onClick={() => checkedChannels && setIsModalOpen(true)}
      />

      <DeleteModal
        title="Удаление канала"
        text={`Удалить ${checkedChannels?.length === 1 ? 'канал' : 'каналы'}`}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDelete()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
