import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { bytesOrderSelect, functionSelect } from 'widgets/plugins/modbus/config'
import type { ModbusRTUSettingsType, ModbusRequest } from 'widgets/plugins/modbus'
import { eventSelect } from 'entities/module'
import { AddModal } from 'shared/ui/modals/add-modal'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import { filterNums } from 'shared/lib/filter-nums'
import { formValidate } from 'shared/lib/form-validate'

const { Option } = Select

export const AddRequest: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<ModbusRTUSettingsType>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: ModbusRequest) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      setLoading(true)

      set.requests.push({
        event: values.event,
        period: +values.period,
        timeout: +values.timeout,
        unit: +values.unit,
        addr: +values.addr,
        function: +values.function,
        bytes_order: values.bytes_order,
        channels: []
      })

      set.requests = set.requests.map((r: ModbusRequest, i: number) => ({ ...r, id: i + 1 }))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="запроса"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            event: 0,
            period: 1,
            timeout: 1,
            unit: 0,
            addr: 0,
            function: 1,
            bytes_order: 0,
          }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<ModbusRequest>
            label="Событие выполнения запроса"
            name="event"
          >
            <Select popupMatchSelectWidth={false}>
              {Object.entries(eventSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Период отправки запроса"
            name="period"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Таймаут ожидания ответа"
            name="timeout"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Адрес устройства"
            name="unit"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Начальный адрес регистра"
            name="addr"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Функция запроса"
            name="function"
          >
            <Select popupMatchSelectWidth={false}>
              {Object.entries(functionSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<ModbusRequest>
            label="Порядок следования байт данных в пакете"
            name="bytes_order"
          >
            <Select popupMatchSelectWidth={false}>
              {Object.entries(bytesOrderSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
