import React, { FC } from 'react'
import { Form } from 'antd'
import type { ChannelsTableData } from 'entities/channel'
import { EditableContext } from 'shared/context/editable-context'

export const EditableRow: FC<{ handleChange: (record: ChannelsTableData) => void }> = ({ handleChange, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={{ form, handleChange }}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
