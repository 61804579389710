import type { CustomColumn } from 'shared/ui/table/table-responsive'
import type { OPCNode } from './types'

export const commands = [
  {
    label: 'Получить структуру',
    command: 'get_struct'
  },
  {
    label: 'Отобразить структуру',
    command: 'create_channels'
  },
]

export const idTypeSelect = {
  'NUM': 0,
  'STR': 1,
  'GUID': 2,
}

export const tagTypeSelect = {
  'Boolean': 1,
  'SByte': 2,
  'Byte': 3,
  'Int16': 4,
  'UInt16': 5,
  'Int32': 6,
  'UInt32': 7,
  'Int64': 8,
  'UInt64': 9,
  'Float': 10,
  'Double': 11,
  'String': 12,
  'DateTime': 13,
  'Guid': 14,
  'ByteString': 15,
  'XmlElement': 16,
  'NodeId': 17,
  'ExpandedNodeId': 18,
  'StatusCode': 19,
  'QualifiedName': 20,
  'LocalizedText': 21,
  'ExtensionObject': 22,
  'DataValue': 23,
  'Variant': 24,
  'DiagnosticInfo': 25,
}

export const modeSelect = {
  'Только чтение': 1,
  'Только запись': 2,
  'Чтение/запись': 3,
}

export const COLUMNS: CustomColumn[] = [
  {
    title: 'Имя тега',
    dataIndex: 'label',
    key: 'label',
    width: 120,
    editable: true,
  },
  {
    title: 'Тип идентификатора',
    dataIndex: ['node_id', 'type'],
    key: 'node_id_type',
    width: 140,
    editable: true,
    inputType: 'select' as const,
    selectData: idTypeSelect,
    valueType: 'number' as const,
    render: (text: OPCNode) => Object.keys(idTypeSelect)[+text],
  },
  {
    title: 'Пространство имён',
    dataIndex: ['node_id', 'ns'],
    key: 'node_id_ns',
    editable: true,
    valueType: 'number' as const,
    width: 120,
  },
  {
    title: 'Идентификатор',
    dataIndex: ['node_id', 'id'],
    key: 'node_id_id',
    width: 130,
    editable: true,
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    width: 140,
    editable: true,
  },
  {
    title: 'Режим доступа',
    dataIndex: 'mode',
    key: 'mode',
    width: 140,
    editable: true,
    inputType: 'select' as const,
    selectData: modeSelect,
    valueType: 'number' as const,
    render: (text: string) => (
      Object.keys(modeSelect)[Object.values(modeSelect).indexOf(+text)]
    ),
  },
  {
    title: 'Привязанный канал',
    dataIndex: 'core_channel_uid',
    key: 'core_channel_uid',
    ellipsis: true,
    isChannel: true,
    width: 180,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Тип тега',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    editable: true,
    inputType: 'select' as const,
    selectData: tagTypeSelect,
    valueType: 'number' as const,
    render: (text: string) => (
      Object.keys(tagTypeSelect)[Object.values(tagTypeSelect).indexOf(+text)]
    ),
  },
  {
    title: 'Использование тега',
    dataIndex: 'active',
    key: 'active',
    width: 124,
    editable: true,
    inputType: 'select' as const,
    selectData: { 'true': true, 'false': false },
    render: (text: boolean) => String(text),
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
        ellipsis: col.ellipsis,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        selectData: col.selectData,
        valueType: col.valueType,
        ellipsis: col.ellipsis,
      }),
    }
))
