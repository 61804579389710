import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import type { MenuProps } from 'antd'
import { createVarsTableActions } from 'entities/module'
import type { MenuClickEventHandler, VarItem, VarNames } from 'shared/types'
import { TitleWithActions } from 'shared/ui/table/table-responsive'

interface RowProps extends React.HTMLAttributes<HTMLTableCellElement> {
  dataIndex: string
  title: string
  tableData: { [key: string]: string }[]
  variables: VarItem[]
  varNameField: VarNames
  onMenuClick: (data: { [key: string]: string }[]) => void
  onEdit: (vars: VarItem[]) => Promise<any>
}

const HeaderCell: FC<RowProps> = ({ dataIndex, title, tableData, variables, varNameField, onMenuClick, onEdit, ...restProps }) => {
  const [actions, setActions] = useState<MenuProps['items']>([])

  useEffect(() => {
    if (dataIndex && dataIndex !== '#var#' && variables)
      setActions(createVarsTableActions(dataIndex, variables, varNameField, onEdit))
  }, [dataIndex])

  const onMenuClickHandler: MenuClickEventHandler = ({ key, dataIndex }) => {
    if (key === '1' && dataIndex && variables) {
      const currVar = variables.find((v: any) => v[varNameField] === dataIndex)
      const data = tableData.reduce<{ [key: string]: string }[]>((acc, curr, idx) => {
        if (curr[dataIndex] && currVar)
          acc.push({
            key: 'channel-' + currVar.channels[idx],
            name: curr[dataIndex]
          })

        return acc
      }, [])

      onMenuClick(data)
    }
  }

  return (dataIndex && dataIndex !== '#var#') ? (
    <th {...restProps}>
      <TitleWithActions
        title={title}
        actions={actions}
        onClick={(info) => onMenuClickHandler({ ...info, dataIndex })}
      />
    </th>
  ) : <th {...restProps} />
}

export default HeaderCell
