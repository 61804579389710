import { ChannelsResponse } from 'entities/channel'
import { DirTreeData } from 'entities/directory'
import type { ModuleSettings } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'
import { createObjectsArray } from 'shared/lib/create-objects-array'
import type { OPCTableData } from '../types'
import type { OPCObjectType } from '../types'

export const createTableData = (settings: ModuleSettings<OPCObjectType>, channelsData: ChannelsResponse | undefined, selectedFolder: DirTreeData): OPCTableData[] => {
  const objects = createObjectsArray(settings, +keyToId(selectedFolder.key))

  return settings.config.objects_list.reduce<OPCTableData[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      const channel = channelsData?.channels.find(ch => ch.uid === curr.object.core_channel_uid)

      acc.push({
        key: 'object-' + curr.ind,
        label: curr.object.label,
        node_id: {
          type: curr.object.node_id.type,
          ns: curr.object.node_id.ns,
          id: curr.object.node_id.id,
        },
        description: curr.object.description,
        mode: curr.object.mode,
        core_channel_uid: { name: channel ? channel.name : '', id: channel ? channel.uid : '' },
        type: curr.object.type,
        active: curr.object.active,
      })
    }

    return acc
  }, [])
}
