import type { SelectedDirType } from 'shared/types'

enum ActionTypes {
  'SET_CHANNELS_ARR',
  'SET_SELECTED_DIRECTORY'
}

export type BindingReducerAction = {
  type: ActionTypes,
  payload: any
}

export type BindingReducerState = {
  channelsArr: string[] | null,
  selectedDirectory: SelectedDirType
}

export const bindingInitState: BindingReducerState = {
  channelsArr: null,
  selectedDirectory: { key: '', title: '' }
}

export const setChannelsArr = (value: string[] | null) => ({
  type: ActionTypes.SET_CHANNELS_ARR,
  payload: value
})

export const setSelectedDirectory = (value: { key: string, title: string }) => ({
  type: ActionTypes.SET_SELECTED_DIRECTORY,
  payload: value
})

export const bindingReducer = (state: BindingReducerState, action: BindingReducerAction): BindingReducerState => {
  switch (action.type) {
    case ActionTypes.SET_CHANNELS_ARR:
      return {
        ...state,
        channelsArr: action.payload
      }
    case ActionTypes.SET_SELECTED_DIRECTORY:
      return {
        ...state,
        selectedDirectory: action.payload
      }
    default:
      return state
  }
}
