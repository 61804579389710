import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import type { DatabaseSettingsType, DatabaseFields } from '../types'

export const DbForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<DatabaseSettingsType>>(ModuleContext)
  const [form] = Form.useForm()

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        host: settings.host,
        db: settings.db,
        user: settings.user,
        password: settings.password,
      })
    }
  }, [settings])

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<DatabaseFields>
            label={'Адрес сервера'}
            name={'host'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  host: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DatabaseFields>
            label={'Имя БД'}
            name={'db'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  db: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DatabaseFields>
            label={'Имя пользователя'}
            name={'user'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  user: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DatabaseFields>
            label={'Пароль '}
            name={'password'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  password: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
