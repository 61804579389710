import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { LogWithKey, StateType } from '../types'
import { ITEMS_TO_SAVE } from '../config'

const initialState: StateType = {
  isLogsVisible: true,
  logItems: []
}

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    openLogs: (state) => {
      state.isLogsVisible = true
    },
    closeLogs: (state) => {
      state.isLogsVisible = false
    },
    toggleLogs: (state) => {
      state.isLogsVisible = !state.isLogsVisible
    },
    addLogs: (state, action: PayloadAction<LogWithKey[]>) => {
      const sorted = action.payload
        .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))

      if (sorted.length > ITEMS_TO_SAVE)
        state.logItems = sorted.slice(0, ITEMS_TO_SAVE)
      else
        state.logItems = sorted
    }
  },
})

export const logReducer = logSlice.reducer
export const { openLogs, closeLogs, toggleLogs, addLogs } = logSlice.actions
export const isLogsVisible = (state: RootState) => state.log.isLogsVisible
export const selectLogs = (state: RootState) => state.log.logItems
