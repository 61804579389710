import type { ChannelColumns } from 'entities/channel'
import { qualitySelect, typesSelect } from 'entities/channel'
import type { OmitChannelType } from 'entities/channel'

export const FETCH_DELAY = 1000
export const COLUMNS: ChannelColumns = [
  {
    title: 'Номер',
    dataIndex: 'id',
    key: 'id',
    width: 80,
    sorter: true
  },
  {
    title: 'Имя канала',
    dataIndex: 'name',
    key: 'name',
    editable: true,
    validateRules: [
      {
        required: true,
        message: 'Введите имя канала',
      },
    ],
    sorter: true
  },
  {
    title: 'Тип',
    key: 'type',
    dataIndex: 'type',
    editable: true,
    inputType: 'select' as const,
    selectData: typesSelect,
    render: (text: string) => Object.keys(typesSelect)[+text] || 'UNSUPPORTED'
  },
  {
    title: 'Значение',
    dataIndex: 'value',
    key: 'value',
    editable: true,
  },
  {
    title: 'Качество',
    dataIndex: 'quality',
    key: 'quality',
    width: 110,
    editable: true,
    inputType: 'select' as const,
    selectData: qualitySelect,
    render: (text: string) => (
      Object.entries(qualitySelect).find((arr) => arr[1] === +text)?.[0]
    )
  },
  {
    title: 'Время обновления',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Группа',
    dataIndex: 'group',
    key: 'group',
    editable: true,
    sorter: true
  },
  {
    title: 'Описание',
    key: 'description',
    dataIndex: 'description',
    editable: true,
  },
].map(col => (
  !col.editable
    ? col
    : {
      ...col,
      onCell: (record: OmitChannelType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: (record.type === 0 && col.dataIndex === 'value') ? 'select' : col.inputType,
        selectData: (record.type === 0 && col.dataIndex === 'value') ? { 'true': 'true', 'false': 'false' } : col.selectData,
        validateRules: col.validateRules,
      }),
    }
))
