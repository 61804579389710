import React from 'react'
import type { Dispatch } from 'react'
import type { ModuleReducerAction, ModuleReducerState } from './module-reducer'

export type ModuleContextType<T = any> = {
  state: ModuleReducerState<T>
  reducerDispatch: Dispatch<ModuleReducerAction>
}

export const ModuleContext = React.createContext<ModuleContextType>(undefined as any)
