import React, { useEffect, useRef, useState } from 'react'
import type { FC, Key, ElementType } from 'react'
import cn from 'classnames'
import { useAppSelector } from 'app/store'
import type { ModuleReducerAction } from 'layouts/module-layout'
import { selectActiveTab } from 'entities/tab'
import { createFoldersTree, ModuleSettings } from 'entities/module'
import type { ChannelsTableData, ChannelsResponse } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import type { ObjectsTableData, ObjetsToSaveFn, ObjectsFieldsType } from 'shared/ui/module-objects'
import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { ObjectsTable } from 'shared/ui/table/objects-table'
import { Folders } from 'shared/ui/folders'
import css from './Objects.module.scss'

type ObjectsType = {
  settings: any
  reducerDispatch: React.Dispatch<ModuleReducerAction>
  columns: CustomColumn[],
  objectsChannelFields: ObjectsFieldsType<any>[]
  AddComponent: ElementType
  createChannelsArr: (settings: ModuleSettings, dirId: number) => string[] | null
  createTableData: (settings: ModuleSettings, channelsData: ChannelsResponse | undefined, selectedFolder: DirTreeData) => any,
  getObjectsToSave: ObjetsToSaveFn
}

export const ModuleObjects: FC<ObjectsType> = ({
  settings,
  reducerDispatch,
  columns,
  objectsChannelFields,
  AddComponent,
  createChannelsArr,
  createTableData,
  getObjectsToSave
}) => {
  const [dirs, setDirs] = useState<DirTreeData[]>([])
  const [tableData, setTableData] = useState<ObjectsTableData[]>([])
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(['dir-0'])
  const [selectedFolder, setSelectedFolder] = useState<DirTreeData | null>(null)
  const [selectedObjectsKeys, setSelectedObjectsKeys] = useState<Key[]>([])
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const [totalObjects, setTotalObjects] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const contentRef = useRef<HTMLDivElement>(null)
  const activeTab = useAppSelector(selectActiveTab)
  const { data: channelsData, isLoading } = useChannels(channelsArr, 0, 0, '', 0, 0)

  useEffect(() => {
    if (settings) {
      setDirs([{
        title: activeTab ? activeTab.label : '',
        key: 'dir-0',
        children: createFoldersTree(settings.config.folders_list)
      }])

      if (selectedFolder) {
        setChannelsArr(createChannelsArr(settings, +keyToId(selectedFolder.key)))
        setTableData(createTableData(settings, channelsData, selectedFolder))
      }
    }
  }, [settings])

  useEffect(() => {
    if (channelsData && selectedFolder)
      setTableData(() => createTableData(settings, channelsData, selectedFolder))
  }, [channelsData])

  useEffect(() => {
    setTotalObjects(tableData.length)
  }, [tableData])

  useEffect(() => {
    if (!channelsArr) setTableData([])

    if (channelsArr?.length === 0 && selectedFolder)
      setTableData(() => createTableData(settings, channelsData, selectedFolder))
  }, [channelsArr])

  useEffect(() => {
    if (activeTab)
      setDirs(prev => ([{
        title: activeTab ? activeTab.label : '',
        key: prev[0].key,
        children: prev[0].children,
      }]))
  }, [activeTab])

  const onSelectHandler = (dir: DirTreeData) => {
    setSelectedFolder(dir)
    setTotalObjects(0)

    if ((!dir.objects?.length && !dir.children?.length) || dir.key === 'dir-0') {
      setChannelsArr(null)
    } else {
      setChannelsArr(createChannelsArr(settings, +keyToId(dir.key)))
    }

    setTableData(() => createTableData(settings, channelsData, dir))
  }

  return (
    <div
      className={cn([css.Objects, 'bd-top'])}
      ref={contentRef}
    >
      <div className={cn([css.Objects__folders, 'bd-right'])}>
        <Folders
          dirs={dirs}
          expandedKeys={expandedKeys}
          selectedFolder={{
            key: selectedFolder ? selectedFolder.key : '',
            title: selectedFolder ? selectedFolder.title : ''
          }}
          onSelect={onSelectHandler}
          onExpand={(keys) => setExpandedKeys(keys)}
        />
      </div>

      <ObjectsTable
        settings={settings}
        reducerDispatch={reducerDispatch}
        columns={columns}
        tableData={tableData}
        channels={channelsData ? channelsData.channels : []}
        isLoading={isLoading}
        parentRef={contentRef}
        selectedObjects={selectedObjectsKeys}
        AddComponent={
          <AddComponent
            key={'AddComponent'}
            settings={settings}
            selectedFolder={selectedFolder}
            objectsChannelFields={objectsChannelFields}
          />
        }
        objectToSaveFn={getObjectsToSave}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: [10, 20, 50, 100],
          hideOnSinglePage: totalObjects <= 10,
          showSizeChanger: true,
          total: totalObjects,
          onChange: (page) => setCurrentPage(page),
          onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedObjectsKeys ?? [],
          onChange: (selectedRowKeys: Key[], selectedRows, info) => {
            if (info.type !== 'all')
              setSelectedObjectsKeys(selectedRowKeys)
          },
          onSelectAll: (selected) => {
            if (selected)
              setSelectedObjectsKeys(tableData.map(item => item.key))
            else
              setSelectedObjectsKeys([])
          },
          getCheckboxProps: (record: ChannelsTableData) => ({
            name: String(record.key),
          }),
        }}
        onDelete={() => setSelectedObjectsKeys([])}
      />
    </div>
  )
}
