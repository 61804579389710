import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'
import type { Module, ModuleState, ModulesType, Plugin } from '../types'

const initialState: ModuleState = {
  isModulesVisible: true,
  types: [],
  plugins: [],
  modules: [],
  selectedPlugin: null,
  activeModule: {
    key: '',
    name: ''
  }
}

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    openModules: (state) => {
      state.isModulesVisible = true
    },
    closeModules: (state) => {
      state.isModulesVisible = false
    },
    toggleModules: (state) => {
      state.isModulesVisible = !state.isModulesVisible
    },
    setTypes: (state, action: PayloadAction<ModulesType[]>) => {
      state.types = action.payload
    },
    setPlugins: (state, action: PayloadAction<Plugin[]>) => {
      state.plugins = action.payload
    },
    setModules: (state, action: PayloadAction<Module[]>) => {
      state.modules = action.payload
    },
    setSelectedPlugin: (state, action: PayloadAction<string | null>) => {
      state.selectedPlugin = action.payload
    },
    setActiveModule: (state, action: PayloadAction<{ key: string, name: string }>) => {
      state.activeModule = action.payload
    },
  },
})

export const moduleReducer = moduleSlice.reducer
export const { openModules, closeModules, toggleModules, setSelectedPlugin, setActiveModule, setTypes, setPlugins, setModules } = moduleSlice.actions
export const isModulesVisible = (state: RootState) => state.module.isModulesVisible
export const selectedPlugin = (state: RootState) => state.module.selectedPlugin
export const selectActiveModule = (state: RootState) => state.module.activeModule
export const selectTypes = (state: RootState) => state.module.types
export const selectPlugins = (state: RootState) => state.module.plugins
export const selectModules = (state: RootState) => state.module.modules
