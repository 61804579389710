import { CustomColumn } from 'shared/ui/table/table-responsive'

export const operationsSelect = {
  'Get': 'Get',
  'GetNext': 'GetNext',
  'Bulk': 'Bulk',
  'Set': 'Set',
}

export const versionSelect = {
  'SNMPv1': 1,
  'SNMPv2': 2,
  'SNMPv3': 3,
}

export const COLUMNS: CustomColumn[] = [
  {
    title: 'Идентификатор объекта',
    dataIndex: 'OID',
    key: 'OID',
    editable: true,
  },
  {
    title: 'Тип запроса для агента',
    dataIndex: 'operations',
    key: 'operations',
    editable: true,
    inputType: 'select' as const,
    selectData: operationsSelect,
    render: (text: keyof typeof operationsSelect) => operationsSelect[text]
  },
  {
    title: 'Канал',
    dataIndex: 'result_channel',
    key: 'result_channel',
    isChannel: true,
    render: (text: string) => text ?? 'Not found',
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        selectData: col.selectData,
      }),
    }
))
