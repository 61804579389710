import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { eventSelect } from 'entities/module'

export const paritySelect = {
  'SERIAL_PARITY_NONE': 0,
  'SERIAL_PARITY_EVEN': 1,
  'SERIAL_PARITY_ODD': 2,
}

export const flowSelect = {
  'Выкл.': 0,
  'Вкл.': 1,
}

export const bytesOrderSelect = {
  '1_0': 0,
  '0_1': 1,
  '3_2_1_0': 2,
  '0_1_2_3': 3,
  '2_3_0_1': 4,
  '1_0_3_2': 5,
  '7_6_5_4_3_2_1_0': 6,
  '0_1_2_3_4_5_6_7': 7,
}

export const functionSelect = {
  ' 1 (Read Coil Status)': 1,
  ' 2 (Read Input Status)': 2,
  ' 3 (Read Holding Registers)': 3,
  ' 4 (Read Input Registers)': 4,
  ' 5 (Force Single Coil)': 5,
  ' 6 (Preset Single Register)': 6,
  ' 15 (Force Multiple Coils)': 15,
  ' 16 (Preset Multiple Registers)': 16,
}

export const COLUMNS: CustomColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 40,
  },
  {
    title: 'Событие',
    dataIndex: 'event',
    key: 'event',
    width: 160,
    editable: true,
    inputType: 'select' as const,
    selectData: eventSelect,
    render: (text: string) => Object.keys(eventSelect)[+text]
  },
  {
    title: 'Период',
    dataIndex: 'period',
    key: 'period',
    width: 100,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Таймаут',
    dataIndex: 'timeout',
    key: 'timeout',
    width: 100,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Адрес устройства',
    dataIndex: 'unit',
    key: 'unit',
    width: 100,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Начальный адрес регистра',
    dataIndex: 'addr',
    key: 'addr',
    width: 100,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Функция запроса',
    dataIndex: 'function',
    key: 'function',
    width: 160,
    editable: true,
    inputType: 'select' as const,
    selectData: functionSelect,
    render: (text: string) => (
      Object.entries(functionSelect).find((arr) => arr[1] === +text)?.[0]
    )
  },
  {
    title: 'Порядок байт',
    dataIndex: 'bytes_order',
    key: 'bytes_order',
    width: 120,
    editable: true,
    inputType: 'select' as const,
    selectData: bytesOrderSelect,
    render: (text: string) => Object.keys(bytesOrderSelect)[+text]
  },
].map(col => (
  !col.editable
    ? col
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        valueType: col.valueType,
        selectData: col.selectData,
      }),
    }
))
