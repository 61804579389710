export default (timestamp: number): string => {
  const data = new Date(timestamp)
  const day = dateMonthFormat(data.getDate())
  const month = dateMonthFormat(data.getMonth() + 1)
  const year = data.getFullYear()
  const time = data.toLocaleTimeString('ru-RU')

  return `${year}-${month}-${day} ${time}`
}

function dateMonthFormat(value: number): string {
  return (value < 10) ? '0' + value : String(value)
}

