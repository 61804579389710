import type { CustomColumn } from 'shared/ui/table/table-responsive'

export const COLUMNS: CustomColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 60,
  },
  {
    title: 'Шаблон',
    dataIndex: 'template',
    key: 'template',
    ellipsis: true,
    render: (text: string) => decodeURIComponent(text),
  },
  {
    title: 'Канал JSON',
    dataIndex: 'channel-json',
    key: 'channel-json',
    width: 240,
    isChannel: true,
    ellipsis: true,
    render: (text: string) => text ?? 'Not found',
  },
].map(col => (
  {
    ...col,
    onCell: (record: any) => ({
      record,
      dataIndex: col.dataIndex,
      isChannel: col.isChannel,
      ellipsis: col.isChannel ? col.ellipsis : undefined,
    }),
  }
))
