import type { CustomColumn } from 'shared/ui/table/table-responsive'

export const commands = [
  {
    label: 'Получить структуру',
    command: 'get_struct'
  },
  {
    label: 'Отобразить структуру',
    command: 'create_channels'
  },
]

export const typeSelect = {
  'DIGITAL': 1,
  'LOGICAL': 2,
  'DIGITAL_ARRAY': 3,
  'COMMON': 4,
  'INTEGER': 5,
  'SET': 6,
  'STRING': 9,
}

export const COLUMNS: CustomColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    editable: true,
  },
  {
    title: 'Название',
    dataIndex: 'label',
    key: 'label',
    width: 120,
    editable: true,
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: 140,
    editable: true,
    inputType: 'select' as const,
    selectData: typeSelect,
    valueType: 'number' as const,
    render: (text: string) => Object.entries(typeSelect).find((arr) => arr[1] === +text)?.[0]
  },
  {
    title: 'Период',
    dataIndex: 'period',
    key: 'period',
    editable: true,
    valueType: 'number' as const,
    width: 120,
  },
  {
    title: 'Привязанный канал',
    dataIndex: 'core_channel_uid',
    key: 'core_channel_uid',
    ellipsis: true,
    isChannel: true,
    width: 180,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Минимальное критическое значение',
    dataIndex: 'critical_bottom_value',
    key: 'critical_bottom_value',
    width: 120,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Максимальное критическое значение',
    dataIndex: 'critical_top_value',
    key: 'critical_top_value',
    width: 120,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Минимальное предупреждающее значение',
    dataIndex: 'warning_bottom_value',
    key: 'warning_bottom_value',
    width: 150,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Максимальное предупреждающее значение',
    dataIndex: 'warning_top_value',
    key: 'warning_top_value',
    width: 150,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Активный',
    dataIndex: 'active',
    key: 'active',
    width: 100,
    editable: true,
    inputType: 'select' as const,
    selectData: { 'true': true, 'false': false },
    render: (text: boolean) => String(text)
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
        ellipsis: col.ellipsis,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        selectData: col.selectData,
        valueType: col.valueType,
        ellipsis: col.ellipsis,
      }),
    }
))
