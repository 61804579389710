import type { ModuleSettings } from 'entities/module'
import { createObjectsArray } from 'shared/lib/create-objects-array'

export const createSearchChannelsArr = (settings: ModuleSettings<any>, selectedFolderId: number): string[] | null => {
  const objects = createObjectsArray(settings, selectedFolderId)

  const arr = settings.config.objects_list.reduce<string[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      return acc.concat([
        curr.object.primary_category_channel,
        curr.object.person_id_channel,
        curr.object.zone_channel,
      ]).filter(item => item !== '')
    }

    return acc
  }, [])

  return arr.length ? arr : null
}
