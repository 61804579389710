import React from 'react'
import type { FC } from 'react'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { MenuInfo } from 'shared/types'

type AppDropdownMenuProps = {
  items: MenuProps['items']
  title: string
  selectable?: boolean
  selectedKeys?: string[] | undefined
  actions?: {[key: string]: () => void} | undefined
  onClick?: (e: MenuInfo) => void
}

export const AppDropdownMenu: FC<AppDropdownMenuProps> = ({ items, title, selectable = false, selectedKeys, actions, onClick }) => {
  return (
    <Dropdown
      menu={{
        items,
        selectable,
        multiple: selectable,
        selectedKeys,
        onClick: ({ key, ...rest }) => {
          if (onClick)
            onClick({ key, ...rest })

          if (actions && actions[key])
            actions[key]()
        }
      }}
      key={title}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {title}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}
