import React from 'react'
import type { FC, ReactNode } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'shared/types'

type AddModalProps = ModalProps & {
  children: ReactNode
  onAdd: () => void
}

export const AddModal: FC<AddModalProps> = ({ title, open, confirmLoading, children, onAdd, onCancel }) => {
  return (
    <Modal
      title={`Добавление ${title}`}
      open={open}
      onOk={onAdd}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      cancelText={'Отмена'}
      okText={'Добавить'}
      forceRender
    >
      {children}
    </Modal>
  )
}
