import React, { useContext } from 'react'
import type { FC } from 'react'
import { Space } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddJSONHandler } from 'features/module/json/add-json-handler'
import { EditJSONHandler } from 'features/module/json/edit-json-handler'
import { RequestsTable } from 'shared/ui/table/requests-table'
import { COLUMNS } from './config'
import type { JSONSettings } from './types'

export const JsonParser: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<JSONSettings>>(ModuleContext)

  return (
    <RequestsTable<JSONSettings>
      columns={COLUMNS}
      settings={settings}
      reducerDispatch={reducerDispatch}
      isChanged={isChanged}
      itemsName={'handlers'}
      itemPrefix={'handler-'}
      deleteText={'Удалить обработчик'}
      rowSelectionWidth={66}
      headerComponents={[<AddJSONHandler key={'AddJSONHandler'} />]}
      renderSelectionCell={(checked, record, index, originNode) => (
        <div style={{ display: 'flex' }}>
          <Space>
            {originNode}
            <EditJSONHandler
              settings={settings}
              handlerId={record.id}
            />
          </Space>
        </div>
      )}
    />
  )
}
