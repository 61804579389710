import React, { FC, ReactNode } from 'react'
import { Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import type { MenuClickEventHandler } from 'shared/types'

type TitleWithActionsProps = {
  title: ReactNode
  actions: MenuProps['items']
  onClick: MenuClickEventHandler
}

export const TitleWithActions: FC<TitleWithActionsProps> = ({ title, actions, onClick }) => {
  return (
    <div className={'title-with-actions'}>
      {title}

      <Dropdown
        menu={{
          items: actions,
          onClick
        }}
        placement={'bottomRight'}
        trigger={['click']}
      >
        <Button
          size={'small'}
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </div>
  )
}
