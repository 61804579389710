import React from 'react'
import type { FC, Key } from 'react'
import { AddFolder } from 'features/folder/add-folder'
import { EditFolder } from 'features/folder/edit-folder'
import { DeleteFolder } from 'features/folder/delete-folder'
import type { DirTreeData } from 'entities/directory'
import type { SelectedDirType } from 'shared/types'
import keyToId from 'shared/lib/key-to-id'
import { Directories } from 'shared/ui/directories'

type FoldersProps = {
  dirs: DirTreeData[]
  expandedKeys: Key[]
  selectedFolder: SelectedDirType
  onSelect: (dir: DirTreeData) => void
  onExpand: (keys: Key[]) => void
}

export const Folders: FC<FoldersProps> = ({ dirs, expandedKeys, selectedFolder, onSelect, onExpand }) => {
  return (
    <Directories
      dirs={dirs}
      expandedKeys={expandedKeys}
      selectedDirectory={selectedFolder}
      isLoading={false}
      withHeader
      actions={[
        <AddFolder
          key={'add-folder'}
          folderId={selectedFolder.key ? +keyToId(selectedFolder.key) : null}
        />,
        <EditFolder
          key={'edit-folder'}
          folderId={selectedFolder.key ? +keyToId(selectedFolder.key) : null}
          folderName={selectedFolder.title}
        />,
        <DeleteFolder
          key={'delete-folder'}
          folderId={selectedFolder.key ? +keyToId(selectedFolder.key) : null}
          folderName={selectedFolder.title}
        />
      ]}
      onSelect={onSelect}
      onExpand={onExpand}
    />
  )
}
