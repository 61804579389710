import type { ColumnsType } from 'antd/es/table'
import { LogWithKey } from 'entities/log'

export const FETCH_DELAY = 1000
export const COLUMNS: ColumnsType<LogWithKey> = [
  {
    title: 'Метка времени',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'Источник',
    dataIndex: 'source',
    key: 'source',
  },
  {
    title: 'Тип события',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Сообщения',
    key: 'message',
    dataIndex: 'message',
  },
]
