import React, { FC } from 'react'
import { useAppSelector } from 'app/store'
import { MainPageProps } from 'app/providers/content-provider'
import { ModuleLayout } from 'layouts/module-layout'
import { Tabs } from 'widgets/tabs'
import { Header } from 'widgets/header'
import { ModulesTree } from 'widgets/modules-tree'
import { EventLogs } from 'widgets/event-logs'
import { selectActiveTab } from 'entities/tab'
import { isLogsVisible } from 'entities/log'
import { isModulesVisible } from 'entities/module'

const Main: FC<MainPageProps> = ({ ActiveComponent }) => {
  const isLogsShow = useAppSelector(isLogsVisible)
  const isModulesShow = useAppSelector(isModulesVisible)
  const activeTab = useAppSelector(selectActiveTab)

  return (
    <div className="app">
      <Header />

      <div className={'content'}>
        <Tabs />

        <div className={'working-content'}>
          <div className={'working-area'}>
            {ActiveComponent ? (
              (activeTab?.key === 'channels' || activeTab?.key === 'license') ? (
                <ActiveComponent />
              ) : (
                <ModuleLayout>
                  {ActiveComponent && <ActiveComponent />}
                </ModuleLayout>
              )
            ) : 'No data'}
          </div>

          {isModulesShow && <ModulesTree />}
        </div>

        {isLogsShow && <EventLogs />}
      </div>
    </div>
  )
}

export default Main
