import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'app/store/hooks'
import { AddModule } from 'features/module/add-module'
import { DeleteModule } from 'features/module/delete-module'
import { ChangeModule } from 'features/module/change-module'
//import { ControlModules } from 'features/module/control-modules'
import { closeModules, selectActiveModule } from 'entities/module'
import type { Module } from 'entities/module'
import BlockHeader from 'shared/ui/block-header'

const Header: FC<{modules: Module[] | undefined}> = ({ modules }) => {
  const dispatch = useAppDispatch()
  const [currModule, setCurrModule] = useState<Module | undefined>()
  const activeModule = useAppSelector(selectActiveModule)

  useEffect(() => {
    if (activeModule.name) {
      setCurrModule(() => modules?.find(m => m.name === activeModule.name))
    }
  }, [activeModule])

  return (
    <BlockHeader
      actionButtons={[
        <AddModule key={'btn-add-module'}/>,
        <ChangeModule key={'btn-edit-module'} module={currModule}/>,
        <DeleteModule key={'btn-delete-module'} />,
        /*<ControlModules key={'start-all-modules'} action={'start'} />,
        <ControlModules key={'stop-all-modules'} action={'stop'} />,*/
      ]}
      closeButton={
        <Button
          type="default"
          icon={<CloseOutlined />}
          size={'small'}
          onClick={() => dispatch(closeModules())}
        />
      }
    />
  )
}

export default Header
