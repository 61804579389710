import keyToId from '../key-to-id'

export const setObjectsConfig = (settings: any, record: any, changedValue: any) => (
  {
    ...settings,
    config: {
      ...settings.config,
      objects_list: settings.config.objects_list.map((item: any) => (
        (item.ind === +keyToId(record.key))
          ? {
            ...item,
            object: {
              ...item.object,
              ...changedValue
            }
          }
          : item
      ))
    }
  }
)
