export function searchObjectDeep(arr: any[], valueToFind: [string, string[]], childKey: string) {
  let resObj: any[] = []

  for (let i = 0; i < arr.length; i++) {
    if (valueToFind[1].includes(arr[i][valueToFind[0]])) {
      resObj.push(arr[i])
    } else if (arr[i][childKey] && arr[i][childKey].length) {
      resObj = resObj.concat(searchObjectDeep(arr[i][childKey], valueToFind, childKey))
    }
  }

  return resObj
}
