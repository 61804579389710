import type { DirTreeData } from 'entities/directory'
import type { ModuleFolder } from '../types'

export const createFoldersTree = (folders: ModuleFolder[]): DirTreeData[] => {
  const root = folders.filter(item => item.folder.parent === 0)

  return root.map(r => addFolder(folders, r))
}

const addFolder = (folders: ModuleFolder[], currFolder: ModuleFolder) => (
  {
    title: currFolder.folder.label,
    key: `dir-${currFolder.ind}`,
    isLeaf: !currFolder.folder.sub_folders.length,
    dir: String(currFolder.folder.parent),
    objects: currFolder.folder.objects.map(String),
    children: currFolder.folder.sub_folders.length
      ? addChildren(folders, currFolder.folder.sub_folders)
      : []
  }
)

const addChildren = (folders: ModuleFolder[], subdirs: number[]): DirTreeData[] => (
  subdirs.map(sub => {
    const subfolder = folders.find(f => f.ind === sub)!

    return addFolder(folders, subfolder)
  })
)


