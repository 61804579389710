import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { setActiveTab, filterTabs } from 'entities/tab/model'
import { TabWithComponent } from '../types'

const initialState: TabWithComponent[] = [
  {
    key: 'channels',
    label: 'Каналы',
    component: 'channelContent',
    isActive: true
  },
]

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<typeof initialState[0]>) => {
      let cloneState = [...state]

      if (!state.find(item => item.key === action.payload.key)) {
        cloneState.push(action.payload)
        cloneState = setActiveTab(cloneState, action.payload.key)
      } else {
        cloneState = setActiveTab(cloneState, action.payload)
      }

      return cloneState
    },
    removeTab: (state, action: PayloadAction<string>) => (
      filterTabs(state, action.payload)
    ),
    toggleTab: (state, action: PayloadAction<typeof initialState[0]>) => {
      const cloneState = [...state]

      if (!state.find(item => item.key === action.payload.key)) {
        cloneState.push(action.payload)
        return setActiveTab(cloneState, action.payload)
      } else {
        return filterTabs(state, action.payload.key)
      }
    },
    selectTab: (state, action: PayloadAction<string>) => (
      setActiveTab(state, action.payload)
    ),
    resetTabs: () => ([{
      key: 'channels',
      label: 'Каналы',
      component: 'channelContent' as const,
      isActive: true
    }]),
  },
})

export const tabsReducer = tabsSlice.reducer
export const { addTab, removeTab, toggleTab, selectTab, resetTabs } = tabsSlice.actions
export const selectIsChannelsVisible = (state: RootState) => !!state.tabs.find(t => t.key === 'channels')
export const selectActiveTab = (state: RootState) => state.tabs.find(t => t.isActive)
export const selectTabs = (state: RootState) => state.tabs
