import { Key } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction  } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

const initialState: { selectedChannels: Key[] | null, channelsArray: string[] | null } = {
  selectedChannels: null,
  channelsArray: null,
}

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setSelectedChannels: (state, action: PayloadAction<Key[] | null>) => {
      state.selectedChannels = action.payload
    },
    setChannelsArray: (state, action: PayloadAction<string[] | null>) => {
      state.channelsArray = action.payload
    },
  },
})

export const channelReducer = channelSlice.reducer
export const { setSelectedChannels, setChannelsArray } = channelSlice.actions
export const selectedChannels = (state: RootState) => state.channel.selectedChannels
export const selectChannelsArray = (state: RootState) => state.channel.channelsArray
