import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { resetModule, SETTINGS_URL } from 'shared/api/modules'

export const ResetModule: FC<{ id: string | null}> = ({ id }) => {
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  return (
    <Button
      type="primary"
      size={'small'}
      loading={isLoadingBtn}
      onClick={() => {
        setIsLoadingBtn(true)

        resetModule(id)
          .then(() => mutate(`${SETTINGS_URL}${id}`))
          .catch((err) => console.error(err))
          .finally(() => setIsLoadingBtn(false))
      }}
    >
      Сбросить
    </Button>
  )
}
