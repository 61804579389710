import type { ModuleSettings } from 'entities/module'
import { createObjectsArray } from 'shared/lib/create-objects-array'
import type { ObjectType } from 'shared/ui/module-objects'

export const createPosSearchChannelsArr = (settings: ModuleSettings<ObjectType>, dirId: number): string[] => {
  const objects = createObjectsArray(settings, dirId)

  return settings.config.objects_list.reduce<string[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      const channels: string[] = Object.keys(curr.object).map(item => (
        item === 'folder' || item === 'label'
          ? ''
          : `${curr.object[item as keyof ObjectType]}`
      ))

      return acc.concat(channels).filter(item => item !== '')
    }

    return acc
  }, [])
}
