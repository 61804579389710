import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/store'
import { selectActiveModule, setActiveModule } from 'entities/module'
import { removeTab } from 'entities/tab'
import { API_URL } from 'shared/config'
import { deleteModules } from 'shared/api/modules'
import keyToId from 'shared/lib/key-to-id'
import { DeleteModal } from 'shared/ui/modals/delete-modal'

export const DeleteModule: FC = () => {
  const dispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const activeModule = useAppSelector(selectActiveModule)
  const { mutate } = useSWRConfig()

  const onDelete = () => {
    if (activeModule.key) {
      setLoading(true)

      deleteModules([keyToId(activeModule.key)])
        .then(() => {
          setIsModalOpen(false)

          dispatch(removeTab(activeModule.key))
          dispatch(setActiveModule({
            key: '',
            name: ''
          }))

          mutate(`${API_URL}/modules`)
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        title={'Удалить модуль'}
        onClick={() => activeModule.key && setIsModalOpen(true)}
      />

      <DeleteModal
        title="Удаление модуля"
        text={'Удалить модуль'}
        textValue={activeModule.name}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDelete()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
