import React from 'react'
import type { FC } from 'react'
import { Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

export const CellWithButton: FC<any> = ({ children, onClick, ellipsis, ...restProps }) => {
  return (
    <td {...restProps}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span className={ellipsis ? 'ellipsis' : ''}>{children}</span>
        <Button
          style={{ flexShrink: '0' }}
          type="primary"
          icon={<LinkOutlined />}
          size={'small'}
          onClick={() => onClick()}
        />
      </div>
    </td>
  )
}
