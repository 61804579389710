import React, { useEffect, useRef, useState } from 'react'
import { FC } from 'react'
import useSWRMutation from 'swr/mutation'
import { useAppDispatch, useAppSelector } from 'app/store/hooks'
import type { DirTreeData } from 'entities/directory'
import { selectChannelsLength } from 'entities/project'
import {
  createChannelsArr,
  updateTableData,
  createEditedItems,
  generateTableData,
  setSelectedChannels, setChannelsArray,
  selectedChannels, selectChannelsArray
} from 'entities/channel'
import type { ChannelsTableData, ChannelsData, EditChannelType } from 'entities/channel'
import { API_URL } from 'shared/config'
import { getChannels } from 'shared/api/channels'
import { useChannels } from 'shared/hooks/use-channels'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import { EditableRow } from 'shared/ui/table/editable-row'
import { EditableCell } from 'shared/ui/table/editable-cell'
import { COLUMNS } from '../config'
import { Header } from './header'
import css from '../ChannelContent.module.scss'

export const ChannelContent: FC<{ selectedDirectory: DirTreeData | null }> = ({ selectedDirectory }) => {
  const dispatch = useAppDispatch()
  const [tableData, setTableData] = useState<ChannelsData | null>({ query: null, items: [] })
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [itemsToSave, setItemsToSave] = useState<EditChannelType[]>([])
  const [totalChannels, setTotalChannels] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [sortField, setSortField] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<number>(1)
  const channelsRef = useRef<HTMLDivElement>(null)
  const selectedChannelsRows = useAppSelector(selectedChannels)
  const channelsArr = useAppSelector(selectChannelsArray)
  const channelsLength = useAppSelector(selectChannelsLength)
  const { data, isLoading, searchId } = useChannels(channelsArr, currentPage - 1, pageSize, sortField, sortDirection, 1000)
  const { data: allChannelsByDirectory, trigger } = useSWRMutation(
    (searchId !== null) ? `${API_URL}/channels${searchId ? `/?search=${searchId}` : ''}` : null,
    getChannels
  )

  useEffect(() => {
    if (data && data.channels.length) {
      setTableData((prev) => ({
        query: JSON.stringify(channelsArr),
        items: generateTableData(prev, { query: JSON.stringify(channelsArr), items: data.channels }) || []
      }))
      setShowLoading(false)
      setTotalChannels(data.search_size)
    }
  }, [data])

  useEffect(() => {
    if (!channelsArr) {
      setShowLoading(false)
      setTableData({ query: null, items: [] })
    }
  }, [channelsArr])

  useEffect(() => {
    setItemsToSave([])
    setCurrentPage(1)
    setTotalChannels(0)
    dispatch(setSelectedChannels(null))

    if (selectedDirectory) {
      dispatch(setChannelsArray(
        createChannelsArr(selectedDirectory, selectedDirectory.key === 'dir-0', channelsLength)
      ))
    } else {
      dispatch(setChannelsArray(null))
    }
  }, [selectedDirectory])

  useEffect(() => {
    if (allChannelsByDirectory && allChannelsByDirectory.channels.length) {
      dispatch(setSelectedChannels(
        allChannelsByDirectory.channels.map(ch => 'channel-' + ch.uid)
      ))
    }
  }, [allChannelsByDirectory])

  useEffect(() => {
    setShowLoading(isLoading)
  }, [isLoading])

  const handleChange = (record: ChannelsTableData) => {
    if (tableData)
      setTableData(updateTableData(tableData, record))

    setItemsToSave(prev => createEditedItems(prev, record, selectedDirectory))
  }

  return (
    <div className={css.ChannelContent}>
      <Header
        items={itemsToSave}
        activeDirectory={selectedDirectory}
        selectedRows={selectedChannelsRows || []}
        onAction={(loading, type) => {
          setShowLoading(loading)

          if (type === 'change' && !loading) {
            setItemsToSave([])

            setTableData((prev) => ({
              query: JSON.stringify(channelsArr),
              items: prev ? prev.items.map(item => ({ ...item, changed: new Set() })) : []
            }))
          }
        }}
      />

      <div className={css.ChannelContent_content} ref={channelsRef}>
        <TableResponsive
          columns={COLUMNS}
          dataSource={tableData ? tableData.items : []}
          parentRef={channelsRef}
          loading={showLoading}
          showSorterTooltip={false}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            current: currentPage,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 50, 100],
            hideOnSinglePage: totalChannels <= 10,
            showSizeChanger: true,
            total: totalChannels,
            onChange: (page) => setCurrentPage(page),
            onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
          }}
          components={{
            body: {
              row: (props: any) => <EditableRow handleChange={handleChange} {...props} />,
              cell: (props: any) => <EditableCell {...props} />,
            },
          }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedChannelsRows ?? [],
            onSelect: (record, selected, selectedRows) => {
              if (selectedRows.length) {
                if (selected) {
                  selectedChannelsRows
                    ? dispatch(setSelectedChannels([...selectedChannelsRows, record.key]))
                    : dispatch(setSelectedChannels([record.key]))
                } else if (selectedChannelsRows)
                  dispatch(setSelectedChannels(selectedChannelsRows.filter(item => item !== record.key)))
              } else
                dispatch(setSelectedChannels(null))
            },
            onSelectAll: (selected) => {
              if (selected && (totalChannels > pageSize)) {
                setShowLoading(true)
                trigger().finally(() => setShowLoading(false))
              }
              else
                dispatch(setSelectedChannels(null))
            },
            getCheckboxProps: (record: ChannelsTableData) => ({
              name: String(record.key),
            }),
          }}
          onChange={(pagination, filters, sorter, extra) => {
            if (extra.action === 'sort') {
              if (!Array.isArray(sorter)) {
                if (sorter.column) {
                  setSortField(String(sorter.field))
                  setSortDirection((sorter.order === 'ascend') ? 1 : 0)
                } else {
                  setSortField('')
                }
              }
            }
          }}
        />
      </div>
    </div>
  )
}
