import { MenuProps } from 'antd'
import { appStore } from 'app/store'
import { toggleTab } from 'entities/tab'

export const aboutItems: MenuProps['items'] = [
  /*{
    key: 'ab-1',
    label: 'Лицензия'
  },*/
  {
    key: 'ab-2',
    label: 'О программе'
  },
]

export const actions: {[key: string]: () => void} | undefined = {
  'ab-1': () => {
    appStore.dispatch(toggleTab({
      key: 'license',
      label: 'Лицензия',
      component: 'license',
      isActive: true
    }))
  },
}
