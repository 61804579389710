import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { NotificationsHandler, NotificationsSettingsType, NotificationsMessage } from 'widgets/plugins/notifications'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { formValidate } from 'shared/lib/form-validate'
import { omitObject } from 'shared/lib/omit-object'
import { AddModal } from 'shared/ui/modals/add-modal'

export const AddMessage: FC<{ handlerId: number }> = ({ handlerId }) => {
  const { state: { settings } } = useContext<ModuleContextType<NotificationsSettingsType>>(ModuleContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { mutate } = useSWRConfig()

  const onSave = (values: NotificationsMessage) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.handlers = set.handlers.map((handler: NotificationsHandler) => {
        if (handler.id === handlerId) {
          const messages = [
            ...handler.messages,
            {
              id: 0,
              parameters: values.parameters,
              message: values.message,
            }
          ].map((message: NotificationsMessage, i: number) => ({ ...message, id: i + 1 }))

          return { ...handler, messages }
        } else
          return handler
      })

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="сообщения"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onSave)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onSave)
          }}
        >
          <Form.Item
            label="Значение канала"
            name="parameters"
            rules={[{ required: true, message: 'Введите идентификатор' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Текст сообщения"
            name="message"
            rules={[{ required: true, message: 'Введите текст сообщения' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
