import { useEffect, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import type { ChannelsResponse } from 'entities/channel'
import { createChannelsSearch, getChannels } from 'shared/api/channels'
import { API_URL } from '../config'

type UseChannelsType = (
  channelsArr: string[] | null,
  currentPage?: number,
  pageSize?: number,
  sortField?: string,
  sortDirection?: number,
  interval?: number
) => {
  data: ChannelsResponse | undefined,
  isLoading: boolean
  searchId: string | null
}

type Params = {
  search?: string
  page?: string
  pageSize?: string
  sortField?: string
  sortDirection?: string
}

export const useChannels: UseChannelsType = (channelsArr, currentPage, pageSize, sortField, sortDirection, interval = 0) => {
  const [searchId, setSearchId] = useState<string | null>(null)
  const [params, setParams] = useState<Params | null>(null)
  const [queryStr, setQueryStr] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { data: channelsSearch } = useSWRImmutable(
    channelsArr ? [`${API_URL}/channels/search`, channelsArr] : null,
    () => {
      if (channelsArr && channelsArr.length)
        setIsLoading(true)

      return createChannelsSearch(channelsArr)
    }
  )
  const { data: channelsData } = useSWRImmutable(
    queryStr ? `${API_URL}/channels${queryStr ? `/?${queryStr}` : ''}` : null,
    (url: string) => {
      if (!channelsData)
        setIsLoading(true)

      return getChannels(url)
        .finally(() => setIsLoading(false))
    },
    { refreshInterval: interval }
  )

  useEffect(() => {
    if (channelsSearch)
      setSearchId(channelsSearch.search)
  }, [channelsSearch])

  useEffect(() => {
    if (!channelsArr)
      setSearchId(null)
    else if (channelsArr.length === 0)
      setSearchId('')
  }, [channelsArr])

  useEffect(() => {
    setParams({
      search: searchId ? `search=${searchId}` : '',
      page: ((currentPage !== undefined) && (searchId !== null)) ? `page=${currentPage}` : '',
      pageSize: (pageSize && searchId !== null) ? `page_size=${pageSize}` : '',
      sortField: sortField ? `sort_by=${sortField}` : '',
      sortDirection: (sortDirection !== undefined && sortField) ? `sort_forward=${sortDirection}` : '',
    })
  }, [searchId, currentPage, pageSize, sortField, sortDirection])

  useEffect(() => {
    if (params)
      setQueryStr(`${Object.values(params).filter(item => !!item).join('&')}`)
  }, [params])

  return {
    data: channelsData,
    isLoading,
    searchId: searchId
  }
}
