import React, { useContext, useState } from 'react'
import type { FC, Key } from 'react'
import { Button } from 'antd'
import { useSWRConfig } from 'swr'
import { DeleteOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { NotificationsHandler, NotificationsSubscriber } from 'widgets/plugins/notifications'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import { DeleteModal } from 'shared/ui/modals/delete-modal'

type DeleteSubscriberProps = {
  handlerId: number
  selectedKeys: Key[]
  onDeleteEvent: () => void
}

export const DeleteSubscriber: FC<DeleteSubscriberProps> = ({
  selectedKeys,
  handlerId,
  onDeleteEvent,
}) => {
  const { state: { settings } } = useContext<ModuleContextType>(ModuleContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const onDelete = () => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.handlers = set.handlers.map((handler: NotificationsHandler) => {
        if (handler.id === handlerId) {
          return {
            ...handler,
            subscribers: handler.subscribers.filter((subscriber: NotificationsSubscriber) => (
              !selectedKeys.includes(`subscriber-${handler.id}-${subscriber.id}`)
            ))
          }
        } else
          return handler
      })

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          onDeleteEvent()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        disabled={!selectedKeys.length}
        onClick={() => setIsModalOpen(true)}
      />

      <DeleteModal
        title=""
        text={'Удалить абонента'}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDelete()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
