import React, { FC } from 'react'
import { Tabs as AntTabs } from 'antd'
import { useAppSelector, useAppDispatch } from 'app/store/hooks'
import { removeTab, selectTab, selectTabs } from 'entities/tab'

export const ShowContentTabs: FC = () => {
  const dispatch = useAppDispatch()
  const tabs = useAppSelector(selectTabs)

  return (
    <AntTabs
      defaultActiveKey={'1'}
      activeKey={tabs.find(item => item.isActive)?.key}
      items={tabs}
      type="editable-card"
      hideAdd={true}
      onChange={key => dispatch(selectTab(key))}
      onEdit={(key, action) => {
        if (action === 'remove' && typeof key === 'string')
          dispatch(removeTab(key))
      }}
    />
  )
}
