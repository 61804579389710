import { MenuProps } from 'antd'
import { appStore } from 'app/store'
import { toggleTab } from 'entities/tab'
import { toggleModules } from 'entities/module'
import { toggleLogs } from 'entities/log'

export const viewItems: MenuProps['items'] = [
  {
    key: 'view-1',
    label: 'Каналы',
  },
  {
    key: 'view-2',
    label: 'Плагины'
  },
  {
    key: 'view-3',
    label: 'Журнал событий'
  },
]

export const actions: {[key: string]: () => void} | undefined = {
  'view-1': () => {
    appStore.dispatch(toggleTab({
      key: 'channels',
      label: 'Каналы',
      component: 'channelContent',
      isActive: true
    }))
  },
  'view-2': () => {
    appStore.dispatch(toggleModules())
  },
  'view-3': () => {
    appStore.dispatch(toggleLogs())
  },
}
