import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { objectsChannelFields } from 'widgets/plugins/visorlabs'
import type { VisorlabsSettings } from 'widgets/plugins/visorlabs'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelsTableData } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { formValidate } from 'shared/lib/form-validate'
import { omitObject } from 'shared/lib/omit-object'
import { generateInt64 } from 'shared/lib/generate-int64'
import keyToId from 'shared/lib/key-to-id'
import { AddModal } from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

type VisorlabsObjectFormType = {
  label: string
  primary_category_channel: string
  primary_category_channel_name: string
  person_id_channel: string
  person_id_channel_name: string
  zone_channel: string
  zone_channel_name: string
}

type AddVisorlabsObjectProps = {
  settings: VisorlabsSettings
  selectedFolder: DirTreeData | null
}

export const AddVisorlabsObject: FC<AddVisorlabsObjectProps> = ({ settings, selectedFolder }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [activeChannelField, setActiveChannelField] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: VisorlabsObjectFormType) => {
    if (settings && selectedFolder) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.config.objects_list.push({
        ind: generateInt64(),
        object: {
          folder: +keyToId(selectedFolder.key),
          label: values.label,
          primary_category_channel: values.primary_category_channel ? values.primary_category_channel : '',
          person_id_channel: values.person_id_channel ? values.person_id_channel : '',
          zone_channel: values.zone_channel ? values.zone_channel : '',
        }
      })

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue(activeChannelField, keyToId(String(data[0].key)))
      form.setFieldValue(`${activeChannelField}_name`, data[0].name)
    }
    setActiveChannelField('')
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        disabled={!selectedFolder || selectedFolder.key === 'dir-0'}
        onClick={() => {
          if (
            selectedFolder
            && selectedFolder.key
            && selectedFolder.key !== 'dir-0'
          ) setIsModalOpen(true)
        }}
      />

      <AddModal
        title="объекта"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<VisorlabsObjectFormType>
            label="Название"
            name="label"
            rules={[{ required: true, message: 'Введите название' }]}
          >
            <Input />
          </Form.Item>

          {objectsChannelFields.map(item => (
            <FormChannelBinding
              key={`${item.name}_field`}
              label={item.label}
              name={item.name}
              onClick={() => {
                setIsBindingOpen(true)
                setIsBindingActive(true)
                setActiveChannelField(item.name)
              }}
              onClear={() => {
                form.setFieldValue(item.name, '')
                form.setFieldValue(`${item.name}_name`, '')
              }}
            />
          ))}
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setTimeout(() => setIsBindingActive(false), 300)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
