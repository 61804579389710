import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { appStore } from './app/store'
import App from './app'
import './app/styles/index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <App />
    </Provider>
  </React.StrictMode>
)
