import { API_URL } from 'shared/config'
import { httpHandler } from 'shared/lib/http-handler'

export async function getLicense(serial: string): Promise<string> {
  const data = await (await httpHandler(`${API_URL}/license/request/?serial=${serial}`)).json()
  return data.license
}

export async function postLicense(licenseText: string) {
  return await httpHandler(`${API_URL}/license`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ license: licenseText }),
  })
}
