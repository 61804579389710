import React from 'react'
import type { FC } from 'react'
import cn from 'classnames'
import { Spin, Tree } from 'antd'
import type { AntTreeNodeProps } from 'antd/es/tree'
import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons'
import type { DirectoriesProps, DirTreeData } from 'entities/directory'
import Header from './header'
import css from '../Directories.module.scss'

export const Directories: FC<DirectoriesProps> = ({ dirs, expandedKeys, actions, selectedDirectory, draggable = false, isLoading = false, withHeader = false, onExpand, onSelect, onDrop }) => {
  return (
    <div className={cn([css.Directories])}>
      {withHeader && <Header actions={actions} />}

      <div className={css.Directories__content}>
        <div className={css.Directories__list}>
          <Spin spinning={isLoading}>
            {(!!dirs.length) && (
              <Tree
                switcherIcon={({ expanded }: AntTreeNodeProps) => (
                  expanded ? <FolderOpenOutlined /> : <FolderOutlined />
                )}
                draggable={draggable}
                treeData={dirs}
                expandedKeys={expandedKeys}
                selectedKeys={selectedDirectory ? [selectedDirectory.key] : []}
                onExpand={onExpand}
                onSelect={(_, { node }) => onSelect(node as DirTreeData)}
                onDrop={(info) => onDrop && onDrop(info)}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  )
}
