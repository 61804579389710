import React from 'react'
import type { FC, ReactNode } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'shared/types'

type EditModalProps = ModalProps & {
  children: ReactNode
  onSave: () => void
}

export const EditModal: FC<EditModalProps> = ({ title, open, confirmLoading, children, onSave, onCancel }) => {
  return (
    <Modal
      title={`Редактирование ${title}`}
      open={open}
      onOk={onSave}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      cancelText={'Отмена'}
      okText={'Сохранить'}
      forceRender
    >
      {children}
    </Modal>
  )
}
