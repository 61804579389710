import type { CustomColumn } from 'shared/ui/table/table-responsive'
import type { ObjectsFieldsType, ObjectType } from 'shared/ui/module-objects'

export const objectsChannelFields: ObjectsFieldsType<ObjectType>[] = [
  {
    label: 'Канал идентификатора',
    name: 'id_channel'
  },
  {
    label: 'Канал имени',
    name: 'name_channel'
  },
  {
    label: 'Канал широты',
    name: 'lat_channel'
  },
  {
    label: 'Канал долготы',
    name: 'lon_channel'
  },
  {
    label: 'Канал высоты',
    name: 'alt_channel'
  },
  {
    label: 'Канал азимута',
    name: 'azm_channel'
  },
  {
    label: 'Канал скорости',
    name: 'spd_channel'
  },
  {
    label: 'Канал типа инцидента',
    name: 'incident_type_channel'
  },
  {
    label: 'Канал комментария инцидента',
    name: 'incident_comment_channel'
  },
  {
    label: 'Канал времени инцидента',
    name: 'incident_time_channel'
  },
]

export const COLUMNS: CustomColumn[] = [
  {
    title: 'Объект',
    dataIndex: 'label',
    key: 'label',
    width: 140,
    editable: true,
  },
  {
    title: 'Канал идентификатора',
    dataIndex: 'id',
    width: 180,
    key: 'id',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал имени',
    dataIndex: 'name',
    width: 180,
    key: 'name',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал широты',
    dataIndex: 'lat',
    width: 180,
    key: 'lat',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал долготы',
    dataIndex: 'lon',
    width: 180,
    key: 'lon',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал высоты',
    dataIndex: 'alt',
    width: 180,
    key: 'alt',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал азимута',
    dataIndex: 'azm',
    width: 180,
    key: 'azm',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал скорости',
    dataIndex: 'spd',
    width: 180,
    key: 'spd',
    ellipsis: true,
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал типа инцидента',
    dataIndex: 'incident_type',
    width: 180,
    key: 'incident_type',
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал комментария инцидента',
    dataIndex: 'incident_comment',
    width: 180,
    key: 'incident_comment',
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
  {
    title: 'Канал времени инцидента',
    dataIndex: 'incident_time',
    width: 180,
    key: 'incident_time',
    isChannel: true,
    render: (text: { name: string, id: string }) => text.name ?? 'Not found',
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
        ellipsis: col.ellipsis,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        ellipsis: col.ellipsis,
      }),
    }
))
