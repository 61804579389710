import React, { useState } from 'react'
import type { Key } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import { DeleteModal } from 'shared/ui/modals/delete-modal'

type DeleteRequestProps<T = any> = {
  settings: T
  keys: Key[]
  itemsName: string
  itemPrefix: string
  modalText: string
  isDisabled: boolean
  onDeleteEvent: () => void
}

export const DeleteRequest = <T extends  { [key: string]: any }>({ settings, keys, itemsName, itemPrefix, modalText, isDisabled = false, onDeleteEvent }: DeleteRequestProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const onDelete = () => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      setLoading(true)

      set[itemsName as keyof typeof set] = set[itemsName].filter((item: any) => !keys.includes(itemPrefix + item.id))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          onDeleteEvent()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      />

      <DeleteModal
        text={modalText}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={onDelete}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
