import { ObserverHandlerFn } from './types'

/*
* Пересчитывает размер таблицы в зависимости от
* размера родительского элемента или от
* размера шапки таблицы.
* */
export const resizeObserverHandler: ObserverHandlerFn = (parentDiv, cb) => {
  const tableHeader = parentDiv?.querySelector('.ant-table-header')
  const size: {[key: string]: number} = {}

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(e => {
      const currSize = e.borderBoxSize[0].blockSize

      if (size[e.target.className]) {
        if (size[e.target.className] !== currSize) {
          size[e.target.className] = currSize
          cb(resizeTable(parentDiv))
        }
      } else {
        size[e.target.className] = currSize
        cb(resizeTable(parentDiv))
      }
    })
  })

  if (parentDiv && tableHeader) {
    resizeObserver.observe(parentDiv)
    resizeObserver.observe(tableHeader)
  }

  return resizeObserver
}

const resizeTable = (parentDiv: HTMLDivElement | null) => {
  const parentNode = parentDiv
  const tableHeaderNode = parentNode && parentNode.querySelector('.ant-table-header')
  const parentSizes = parentNode && parentNode.getBoundingClientRect()
  const headerSizes = tableHeaderNode && tableHeaderNode.getBoundingClientRect()

  if (parentSizes && headerSizes)
    return parentSizes.height - headerSizes.height
}
