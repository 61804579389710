import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useAppSelector } from 'app/store'
import getSelectedMenu from './model/get-selected-menu'
import { viewItems, actions } from './config'
import { selectIsChannelsVisible } from 'entities/tab'
import { isLogsVisible } from 'entities/log'
import { isModulesVisible } from 'entities/module'
import { AppDropdownMenu } from 'shared/ui/app-dropdown-menu'

export const AppView: FC = () => {
  const isChannelsShow = useAppSelector(selectIsChannelsVisible)
  const isLogsShow = useAppSelector(isLogsVisible)
  const isPluginsShow = useAppSelector(isModulesVisible)
  const [selectedMenuItems, setSelectedMenuItems] = useState<string[]>([])

  useEffect(() => {
    setSelectedMenuItems(
      getSelectedMenu([
        [isChannelsShow, 'view-1'],
        [isPluginsShow, 'view-2'],
        [isLogsShow, 'view-3']]
      )
    )
  }, [isChannelsShow, isLogsShow, isPluginsShow])

  return (
    <AppDropdownMenu
      title={'Вид'}
      items={viewItems}
      selectable={true}
      selectedKeys={selectedMenuItems}
      actions={actions}
    />
  )
}
