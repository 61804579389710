import React, { FC, ReactElement } from 'react'
import BlockHeader from 'shared/ui/block-header'

const Header: FC<{ actions?: ReactElement[] }> = ({ actions }) => {
  return (
    <BlockHeader actionButtons={actions} />
  )
}

export default Header
