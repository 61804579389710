import React, { FC, useContext, useState } from 'react'
import { useSWRConfig } from 'swr'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { WialonSettings } from 'widgets/plugins/wialon/types'
import { omitObject } from 'shared/lib/omit-object'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { DeleteModal } from 'shared/ui/modals/delete-modal'

export const DeleteFolder: FC<{ folderId: number | null, folderName: string }> = ({ folderId, folderName }) => {
  const { state: { settings } } = useContext<ModuleContextType<WialonSettings>>(ModuleContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const onDelete = () => {
    if (settings && folderId !== null) {
      const set = omitObject(settings, ['uid'])

      set.config.folders_list = set.config.folders_list.filter((f: any) => f.ind !== folderId)

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        onClick={() => {
          if (folderId !== null && folderId !== 0)
            setIsModalOpen(true)
        }}
      />

      <DeleteModal
        title="Удаление директории"
        text={'Удалить директорию'}
        textValue={folderName}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDelete()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
