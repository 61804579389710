import React, { Key, ReactElement, useState } from 'react'
import type { FC } from 'react'
import { Empty, Table, Typography } from 'antd'
import type { TableProps } from 'antd/es/table/InternalTable'
import BlockHeader from 'shared/ui/block-header'
import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { EditableRow } from 'shared/ui/table/editable-row'
import { EditableCell } from 'shared/ui/table/editable-cell'

const { Text } = Typography

type NestedTableProps = {
  tableData: any[]
  columns: CustomColumn[]
  parentId: number
  nestedTitle?: string
  actions?: ReactElement[]
  getDeleteElement: () => FC<any>
  onChangeValue: (record: any, changedValue: any, tableName: string) => void
}

export const NestedTable: FC<TableProps & NestedTableProps> = ({
  tableData,
  columns,
  nestedTitle,
  parentId,
  actions = [],
  getDeleteElement,
  onChangeValue,
  ...props
}) => {
  const [selectedRows, setSelectedRows] = useState<Key[]>([])
  const DeleteItem = getDeleteElement()

  return (
    <div style={{ marginBottom: '8px' }}>
      <div style={{ marginLeft: '40px' }}>
        <Text strong>{nestedTitle ?? ''}</Text>

        <BlockHeader
          actionButtons={[
            ...actions,
            <DeleteItem
              key={'delete-item'}
              handlerId={parentId}
              selectedKeys={selectedRows}
              onDeleteEvent={() => setSelectedRows([])}
            />
          ]}
        />
      </div>

      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          size={'small'}
          components={{
            body: {
              row: (props: any) => <EditableRow handleChange={onChangeValue} {...props} />,
              cell: (props: any) => <EditableCell {...props} />,
            },
          }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys: Key[]) => {
              setSelectedRows(selectedRowKeys)
            },
            getCheckboxProps: (record: any) => ({
              name: String(record.key),
            }),
          }}
          locale={{
            emptyText: <Empty description={`${nestedTitle} отсутствуют.`} imageStyle={{ display: 'none' }} />,
          }}
          {...props}
        />
      </div>
    </div>
  )
}
