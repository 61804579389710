import React, { FC } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export const UnbindChannel: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        onClick={onClick}
      />
    </>
  )
}
