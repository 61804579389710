import type { Channel } from 'entities/channel'
import type { CustomColumn } from 'shared/ui/table/table-responsive'

export const updateTableData = (
  items: any[],
  channels: Channel[] | undefined,
  columns: CustomColumn[],
  itemPrefix: string,
) => {
  return items.map((item: any) => {
    const fields: { [key: string]: string } = {}

    /*
    * На каждом шаге итерации проверяется принадлежность колонки к колонке,
    * содержащей имя канала. Если это условие истинно, то
    * соответствующей ячейке устанавливается имя канала,
    * если нет, то устанавливается текущее значение
    * из настроек
    * */
    columns.forEach(col => {
      const dataIndex = String(col.dataIndex)

      if (col.isChannel) {
        const channel = channels?.find(ch => ch.uid === item[dataIndex])

        fields[dataIndex] = channel ? channel.name : ''
      } else {
        fields[dataIndex] = item[dataIndex]
      }
    })

    return {
      key: itemPrefix + item.id,
      id: item.id,
      ...fields
    }
  })
}
