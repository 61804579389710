import React, { FC, useContext, useState } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { HTTPEndpoint } from 'widgets/plugins/http-server'
import { omitObject } from 'shared/lib/omit-object'
import type { ServerSettingsType } from 'shared/ui/form/server-form/types'
import { AddEndpointModal } from 'shared/ui/modals/add-endpoint-modal'

export const AddHTTPEndpoint: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<ServerSettingsType>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const onAddHandler = (values: { path: string, type: string }) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.nodes?.push({
        url: values.path,
        payload_type: values.type || '',
        channel: ''
      })

      set.nodes = set.nodes?.map((n: HTTPEndpoint, i: number) => ({ ...n, id: i + 1 }))

      return set
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddEndpointModal
        isOpen={isModalOpen}
        uid={settings.uid}
        onAdd={onAddHandler}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
