import type { ModuleSettings } from 'entities/module'

export const createObjectsArray = (settings: ModuleSettings, folderId: number): number[] => (
  settings.config.folders_list.reduce<number[]>((acc, curr) => {
    if (curr.ind === folderId) {
      acc = acc.concat(curr.folder.objects)

      if (curr.folder.sub_folders.length) {
        curr.folder.sub_folders.forEach(item => {
          acc = acc.concat(createObjectsArray(settings, item))
        })
      }
    }

    return acc
  }, [])
)
