import { TabWithComponent } from '../types'

export const filterTabs = (state: TabWithComponent[], key: string) => {
  let deletedIsActive = false

  const tabs = state.filter((item) => {
    if ((item.key === key) && item.isActive)
      deletedIsActive = true

    return item.key !== key
  }, [])

  if (tabs.length) {
    tabs[0] = {
      ...tabs[0],
      isActive: tabs.length === 1 ? true : deletedIsActive
    }
  }


  return tabs
}
