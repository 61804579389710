import React, { useEffect, useLayoutEffect, useState } from 'react'
import type { FC } from 'react'
import cn from 'classnames'
import { Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useAppSelector } from 'app/store'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { selectDirs } from 'entities/directory'
import { useChannels } from 'shared/hooks/use-channels'
import { searchObjectDeep } from 'shared/lib/search-object-deep'
import css from './ChannelsList.module.scss'

type ChannelsListProps = {
  channels: string[]
  title: string
  isLoading: boolean
  onSave: (data: Partial<ChannelsTableData>[]) => void
}

export const ChannelsList: FC<ChannelsListProps> = ({ channels, title, isLoading, onSave }) => {
  const dirs = useAppSelector(selectDirs)
  const [channelsNames, setChannelsNames] = useState<string[]>([])
  const [boundChannels, setBoundChannels] = useState<ChannelItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalActive, setIsModalActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const { data: channelsData, isLoading: isChannelsLoading } = useChannels(channelsArr)

  useLayoutEffect(() => {
    if (channels) {
      if (channels.length)
        setChannelsArr(channels)
      else
        setChannelsArr(null)
    }
  }, [channels])

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (channelsData)
      setChannelsNames(channelsData.channels.map(t => t.name))
  }, [channelsData])

  const openTriggersModal = () => {
    if (channelsData && channelsNames.length) {
      const chDirs = searchObjectDeep(dirs, ['key', channelsData.channels.map(td => 'dir-' + td.dir)], 'children')

      setBoundChannels(() => (
        channelsData.channels.map(t => {
          return {
            key: 'channel-' + t.uid,
            name: t.name,
            dir: chDirs.find(d => d.key === 'dir-' + t.dir)
          }
        })
      ))
    }

    setIsModalActive(true)
    setIsModalOpen(true)
  }

  return (
    <div className={cn([css.ChannelsList, 'bd-top'])}>
      <span>{title}&nbsp;</span>

      <Button
        type="primary"
        icon={<LinkOutlined />}
        size={'small'}
        loading={isChannelsLoading}
        onClick={openTriggersModal}
      /> : {channelsNames.join(', ')}

      {isModalActive && (
        <BindingChannels
          boundChannels={boundChannels || []}
          isLoading={loading}
          isOpen={isModalOpen}
          onSave={onSave}
          afterClose={() => setIsModalActive(false)}
          onClose={() => {
            setIsModalOpen(false)
            setBoundChannels([])
          }}
        />
      )}
    </div>
  )
}
