import React, { FC, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { useAppSelector } from 'app/store'
import { selectDirs } from 'entities/directory'
import type { DirTreeData } from 'entities/directory'
import { changeDirectoryName, DIR_URL } from 'shared/api/directories'
import keyToId from 'shared/lib/key-to-id'
import { formValidate } from 'shared/lib/form-validate'
import { EditModal } from 'shared/ui/modals/edit-modal'

type AddFields = {
  directoryName: string
}

type ChangeDirectoryType = {
  selectedDirectory: DirTreeData | null
  onChanged: (newName: string) => void
}

export const ChangeDirectory: FC<ChangeDirectoryType> = ({ selectedDirectory, onChanged }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const dirs = useAppSelector(selectDirs)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  useEffect(() => {
    if (dirs.length && selectedDirectory)
      form.setFieldValue('directoryName', selectedDirectory.title)
  }, [dirs, selectedDirectory])

  const onChange = (values: AddFields) => {
    setLoading(true)

    if (selectedDirectory && selectedDirectory.key) {
      changeDirectoryName(keyToId(selectedDirectory.key), values.directoryName)
        .then(() => {
          setIsModalOpen(false)
          onChanged(form.getFieldValue('directoryName'))
          mutate(DIR_URL)
          form.resetFields()
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={'small'}
        onClick={() => {
          if ((selectedDirectory?.key !== '') && (selectedDirectory?.key !== 'dir-0'))
            setIsModalOpen(true)
        }}
      />

      <EditModal
        title="директории"
        open={isModalOpen}
        confirmLoading={loading}
        onSave={() => formValidate(form, onChange)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onChange)
          }}
        >
          <Form.Item<AddFields>
            label="Имя директории"
            name="directoryName"
            rules={[{ required: true, message: 'Введите имя директории' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </EditModal>
    </>
  )
}
