import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { useAppSelector } from 'app/store'
import { selectedPlugin } from 'entities/module'
import type { CreateModuleType } from 'entities/module'
import { API_URL } from 'shared/config'
import { createModule } from 'shared/api/modules'
import { formValidate } from 'shared/lib/form-validate'
import { AddModal } from 'shared/ui/modals/add-modal'

export const AddModule: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const activePlugin = useAppSelector(selectedPlugin)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: CreateModuleType) => {
    setLoading(true)

    if (activePlugin) {
      createModule(values.name, activePlugin, values.group, values.description)
        .then(() => {
          setIsModalOpen(false)
          mutate(`${API_URL}/modules`)
          form.resetFields()
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size={'small'}
        title={'Добавить модуль'}
        onClick={() => activePlugin && setIsModalOpen(true)}
      />

      <AddModal
        title="модуля"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<CreateModuleType>
            label="Имя модуля"
            name="name"
            rules={[{ required: true, message: 'Введите имя модуля' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateModuleType>
            label="Группы"
            name="group"
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateModuleType>
            label="Описание"
            name="description"
          >
            <Input />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
