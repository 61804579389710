import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { controlModule, SETTINGS_URL } from 'shared/api/modules'

export const ControlModule: FC<{ id: string | null, isStarted: boolean }> = ({ id, isStarted }) => {
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  return (
    <Button
      type="primary"
      size={'small'}
      loading={isLoadingBtn}
      onClick={() => {
        setIsLoadingBtn(true)

        controlModule(id, isStarted ? 'stop' : 'start')
          .then(() => mutate(`${SETTINGS_URL}${id}`))
          .catch((err) => console.error(err))
          .finally(() => setIsLoadingBtn(false))
      }}
    >
      {isStarted ? 'Стоп' : 'Старт'}
    </Button>
  )
}
