import React from 'react'
import { Form, Button, Space, Input, Flex } from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import type { DeepNamePath } from 'rc-field-form/lib/namePathType'

type FormChannelBindingPropsType = {
  label: string
  name: DeepNamePath
  compact?: boolean
  onClick?: () => void
  onClear?: () => void
}

export const FormChannelBinding = <T,>({ label, name, compact, onClick, onClear }: FormChannelBindingPropsType) => {
  const fieldValue = Form.useWatch(name)

  return (
    <>
      <Form.Item<T>
        label={
          <Space>
            {label}

            {!compact ?
              <Button
                type="primary"
                icon={<LinkOutlined />}
                size={'small'}
                onClick={onClick}
              /> : null
            }
          </Space>
        }
      >
        {compact ? (
          <Space.Compact>
            <Button
              type="primary"
              icon={<LinkOutlined />}
              onClick={onClick}
            />

            <Form.Item name={name + '_name'} noStyle>
              <Input className={'input-disabled'} />
            </Form.Item>

            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              disabled={!fieldValue}
              onClick={onClear}
            />
          </Space.Compact>
        ) : (
          <Flex align={'center'} gap={'small'}>
            <Form.Item name={name + '_name'} noStyle>
              <Input className={'input-disabled'} />
            </Form.Item>

            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size={'small'}
              disabled={!fieldValue}
              onClick={onClear}
            />
          </Flex>
        )}
      </Form.Item>
      <Form.Item name={name} hidden><Input /></Form.Item>
    </>
  )
}
