import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Select, Space } from 'antd'
import { LinkOutlined, PlusOutlined } from '@ant-design/icons'
import CodeMirror from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { xcodeLight } from '@uiw/codemirror-theme-xcode'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import { separatorSelect } from 'widgets/plugins/database'
import type { DatabaseSettingsType, DatabaseRequest } from 'widgets/plugins/database'
import type { ChannelsTableData } from 'entities/channel'
import { eventSelect } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'
import { AddModal } from 'shared/ui/modals/add-modal'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { omitObject } from 'shared/lib/omit-object'
import { filterNums } from 'shared/lib/filter-nums'
import { formValidate } from 'shared/lib/form-validate'

const { Option } = Select

type FieldsType = DatabaseRequest & {
  'channel-response-name': string
}

export const AddRequest: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<DatabaseSettingsType>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: DatabaseRequest) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      setLoading(true)

      set.requests.push({
        id: set.requests.length + 1,
        event: values.event,
        period: +values.period,
        query: encodeURIComponent(values.query),
        'col-separator': values['col-separator'],
        'row-separator': values['row-separator'],
        'channel-response': values['channel-response'],
        aliases: []
      })

      set.requests = set.requests.map((r: DatabaseRequest, i: number) => ({ ...r, id: i + 1 }))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('channel-response', keyToId(String(data[0].key)))
      form.setFieldValue('channel-response-name', data[0].name)
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="запроса"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          setTimeout(() => form.resetFields(), 300)
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            event: 0,
            period: 1,
            query: '',
            'col-separator': '%09',
            'row-separator': '%0A',
            'channel-response': '',
          }}
        >
          <Form.Item<FieldsType>
            label="Событие выполнения запроса"
            name="event"
          >
            <Select popupMatchSelectWidth={false}>
              {Object.entries(eventSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<FieldsType>
            label="Период отправки запроса"
            name="period"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<FieldsType>
            label={
              <Space>
                Канал ответа на запрос
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  size={'small'}
                  onClick={() => {
                    setIsBindingOpen(true)
                    setIsBindingActive(true)
                  }}
                />
              </Space>
            }
            name="channel-response-name"
          >
            <Input className={'input-disabled'} />
          </Form.Item>

          <Form.Item<FieldsType>
            name="channel-response"
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldsType>
            label="Разделитель колонок"
            name="col-separator"
            rules={[{ required: true, message: 'Укажите разделитель' }]}
          >
            <Select>
              {Object.entries(separatorSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<FieldsType>
            label="Разделитель строк"
            name="row-separator"
            rules={[{ required: true, message: 'Укажите разделитель' }]}
          >
            <Select>
              {Object.entries(separatorSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<FieldsType>
            label="Запрос"
            name="query"
            rules={[{ required: true, message: 'Введите код запроса' }]}
          >
            <CodeMirror
              extensions={[langs.pgsql()]}
              theme={xcodeLight}
              height={'300px'}
            />
          </Form.Item>
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingActive(false)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
