import React, { FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import useSWRImmutable from 'swr/immutable'
import { ConfigProvider } from 'antd'
import { ResizableBox } from 'react-resizable'
import { COLUMNS, FETCH_DELAY } from '../config'
import { addKeyField } from 'entities/log'
import { useAppDispatch, useAppSelector } from 'app/store/hooks'
import { selectLogs, addLogs } from 'entities/log'
import { getLogs } from 'shared/api/event-logs'
import { API_URL } from 'shared/config'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import Header from './header'
import css from '../EventLogs.module.scss'

export const EventLogs: FC = () => {
  const dispatch = useAppDispatch()
  const tableRef = useRef(null)
  const { data } = useSWRImmutable(`${API_URL}/log`, getLogs, { refreshInterval: FETCH_DELAY })
  const logs = useAppSelector(selectLogs)

  useEffect(() => {
    if (data && data.length)
      dispatch(addLogs(addKeyField(data)))
  }, [data])

  return (
    <ResizableBox
      height={220} width={window.innerWidth} axis={'y'}
      resizeHandles={['n']}
      minConstraints={[Infinity, 50]}
    >
      <div className={cn([css.EventLog, 'bg-white', 'bd-top'])}>
        <Header />

        <div
          className={css.EventLog_table}
          ref={tableRef}
        >
          <ConfigProvider renderEmpty={() => (<></>)}>
            <TableResponsive
              parentRef={tableRef}
              columns={COLUMNS}
              dataSource={logs}
              loading={!logs.length}
            />
          </ConfigProvider>
        </div>
      </div>
    </ResizableBox>
  )
}
