import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Form, Col, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import type { ModbusTCPSettingsType, ModbusTCPFields } from '../../types'

export const TcpForm: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<ModbusTCPSettingsType>>(ModuleContext)
  const [form] = Form.useForm()

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        ip: settings.ip,
        port: settings.port,
      })
    }
  }, [settings])

  return (
    <Form
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<ModbusTCPFields>
            label={'Адрес'}
            name={'ip'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  ip: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusTCPFields>
            label={'Порт'}
            name={'port'}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  port: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
