import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Form, Input, InputRef, Select } from 'antd'
import type { Rule } from 'antd/es/form'
import { BaseSelectRef } from 'rc-select'
import type { ChannelsTableData } from 'entities/channel'
import { EditableContext } from 'shared/context/editable-context'
import { TableContext } from 'shared/context/table-context'
import { dispatchEvent } from 'shared/lib/custom-events'

const { Option } = Select

type EditableCellProps = {
  title: string
  editable: boolean
  children?: React.ReactNode
  dataIndex: string | string[]
  record: any
  inputType: string
  valueType: string
  selectData: string[]
  validateRules: Rule[]
  handleChange: (record: ChannelsTableData) => void
}

export const EditableCell: FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  inputType,
  valueType,
  selectData,
  validateRules,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const selectRef = useRef<BaseSelectRef >(null)
  const { form, handleChange } = useContext(EditableContext)!
  const { setIsEditing } = useContext(TableContext)!

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      selectRef.current?.focus()
    }

    setIsEditing(editing)
  }, [editing])

  const toggleEdit = () => {
    setEditing(prev => !prev)

    if (!Array.isArray(dataIndex))
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    else
      form.setFieldsValue({ [dataIndex.toString()]: record[dataIndex[0]][dataIndex[1]] })
  }

  const onChange = async (val: string | number) => {
    let returnObject: any = {}

    if (Array.isArray(dataIndex)) {
      returnObject[dataIndex[0]] = record[dataIndex[0]]
      returnObject[dataIndex[0]][dataIndex[1]] = (valueType === 'number') ? +val : val
    } else {
      returnObject = { [dataIndex]: (valueType === 'number') ? +val : val }
    }

    if (!form.getFieldError(dataIndex).length) {
      form.validateFields()
        .then(values => {
          // Записываем имена измененных полей
          if (record.changed)
            Object.keys(values).forEach(v => record.changed.add(v))

          handleChange({ ...record, ...values }, returnObject)
        })
        .catch(err => console.error(err))
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex.toString()}
        rules={validateRules}
      >
        {(inputType === 'select') ? (
          <Select
            ref={selectRef}
            onChange={onChange}
            onBlur={toggleEdit}
            popupMatchSelectWidth={false}
          >
            {Object.entries(selectData).map(([key, val], i) => (
              <Option value={val} key={'type' + i}>{key}</Option>
            ))}
          </Select>
        ) : (
          <Input
            ref={inputRef}
            onChange={e => onChange(e.target.value)}
            onBlur={() => setEditing(prev => !prev)}
            onKeyUp={e => {
              if (e.code === 'Enter') dispatchEvent('channel:onPressEnter')
            }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className={cn([
          'editable-cell-value-wrap',
          { 'editable-cell-value-wrap-empty': !children || (Array.isArray(children) && (children[1] === '' || children[1] === null)) }
        ])}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td { ...restProps }>{childNode}</td>
}
